import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * @param targetRef element reference to validate if is visible in the viewport
 * @returns if visible in the current viewport
 */
const useVisibilityObserver = (targetRef) => {
  const [isVisible, setIsVisible] = useState(false);

  const options = useMemo(() => ({
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  }), []);

  const handleVisibility = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    const { current } = targetRef;
    const observer = new IntersectionObserver(handleVisibility, options);
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [handleVisibility, options, targetRef]);

  return isVisible;
};

export { useVisibilityObserver };
