import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import './CardPlanMainHeaderCompany.css';

const CardPlanMainHeaderCompany = ({ isSideBar }) => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const { guidData, companyName } = store;

  function renderCompanyLogo() {
    if (guidData && guidData.Logo) {
      return (
        <img
          alt={guidData?.DisplayName || companyName}
          className="Brand-logo-image"
          src={guidData.Logo}
        />
      );
    }

    return (
      <span className="Brand-logo-image-text">
        Company Logo
      </span>
    );
  }

  function renderEmployerText() {
    let discountText = 'Employer Discount D';

    if (guidData && guidData.EmployerDiscountText) {
      discountText = guidData.EmployerDiscountText;
    }

    if (guidData && (guidData.EmployerDiscountText === ''
    || guidData.EmployerDiscountText === null)) {
      return null;
    }

    return (
      <span className="Card-plan-header-text-right">
        {discountText}
      </span>
    );
  }

  return (
    <div
      className={`Card-plan-header-container ${
        isSideBar ? 'Card-plan-header-container-landing' : ''}`}
    >
      <div className="Brand-logo-image-container">
        {renderCompanyLogo()}
      </div>

      {renderEmployerText()}
    </div>
  );
};

export default CardPlanMainHeaderCompany;
