import {
  ACCOUNT_NUMBER_CHANGED,
  ACCOUNT_NUMBER_ERROR_CHANGED,
  ACCOUNT_TYPE_CHANGED,
  ADDRESS_BY_ZIP_CODE_LOADED,
  AGE_DATA_ERROR,
  AGE_DATA_LOADED,
  AGREE_TERMS_AND_CONDITION_CHANGED,
  AMERICAN_STATES_LOADED,
  BREED_DATA_ERROR,
  BREED_DATA_LOADED,
  CARD_CVV_CHANGED,
  CARD_CVV_ERROR_CHANGED,
  CARD_NUMBER_CHANGED,
  CARD_NUMBER_ERROR_CHANGED,
  CONFIRM_ACCOUNT_NUMBER_CHANGED,
  CONFIRM_ACCOUNT_NUMBER_ERROR_CHANGED,
  CONSENT_ELECTRONIC_DELIVERY_CHANGED,
  CREATE_DIAMOND_CLIENT_ATTEMPT,
  CREATE_DIAMOND_CLIENT_ERROR,
  CREATE_DIAMOND_CLIENT_SUCCESS,
  CREATE_LIVE_VET_NEW_ACCOUNT_ATTEMPTED,
  CREDIT_CARD_TYPE_CHANGED,
  CURRENT_QUOTE_STEP_CHANGED,
  CUSTOM_VET_CITY_CHANGED,
  CUSTOM_VET_DATA_CLEARED,
  CUSTOM_VET_MODAL_OPENED,
  CUSTOM_VET_PRACTICE_NAME_CHANGED,
  CUSTOM_VET_SAVED,
  CUSTOM_VET_STATE_CHANGED,
  CUSTOMER_VALIDATED,
  CUSTOMER_VALIDATION_ATTEMPT,
  CUSTOMER_VALIDATION_ERROR,
  DEDUCTIBLE_LEGEND_CHANGED,
  DIAMOND_CUSTOMER_LOGIN_ATTEMPT,
  DIAMOND_CUSTOMER_LOGIN_CLEANED,
  DIAMOND_CUSTOMER_LOGIN_FAILED,
  DIAMOND_CUSTOMER_LOGIN_SUCCESS,
  DIAMOND_CUSTOMER_LOGOUT,
  DIAMOND_CUSTOMER_RESETED_LOGIN_FAIL,
  DIAMOND_CUSTOMER_VALIDATION_ATTEMPT,
  DIAMOND_CUSTOMER_VALIDATION_FAILED,
  DIAMOND_CUSTOMER_VALIDATION_LOADED,
  DIAMOND_CUSTOMER_ZIPCODE_CHANGED,
  EFFECTIVE_DATE_CUSTOM_CHANGED,
  EMPLOYER_ID_VALIDATED,
  EMPLOYER_ID_VALIDATION_ATTEMPT,
  ERROR_MODAL_CLOSED,
  EXPIRATION_DATE_CHANGED,
  EXPIRATION_DATE_ERROR_CHANGED,
  FAILED_TO_LOAD_ADDRESS_BY_ZIP_CODE,
  FAILED_TO_LOAD_AMERICAN_STATES,
  FAILED_TO_LOAD_VETERINARIANS,
  FORGET_PASSWORD_CONFIRM_MODAL_VISIBLE_CHANGED,
  FORGET_PASSWORD_MODAL_EMAIL_CHANGED,
  FORGET_PASSWORD_MODAL_EMAIL_ERROR_CHANGED,
  FORGET_PASSWORD_MODAL_VISIBLE_CHANGED,
  FORGET_PASSWORD_RESET_PASSWORD_ATTEMTPED,
  FORGET_RESET_PASSWORD_FAILED,
  FORGET_RESET_PASSWORD_SUCCEEDED,
  GET_SUB_ID_SYNONYMS_DATA_FAILED,
  GUID_DATA_LOADED,
  LIVE_VET_ACCESS_RESET,
  LIVE_VET_ACCESS_VALIDATED,
  LIVE_VET_ACCESS_VALIDATION_ATTEMPTED,
  LIVE_VET_ACCESS_VALIDATION_FAILED,
  LIVE_VET_MODAL_LOGIN_VISIBLE_CHANGED,
  LIVE_VET_NEW_CUSTOMER_VALIDATED,
  LIVE_VET_PET_PARENT_VALIDATED,
  LIVE_VET_SUBSCRIPTION_ATTEMPT,
  LIVE_VET_SUBSCRIPTION_FAILED,
  LIVE_VET_SUBSCRIPTION_RESET,
  LIVE_VET_SUBSCRIPTION_SUCCESS,
  LOAD_MULTI_MODAL_DATA_FAILED,
  LOAD_MULTI_MODAL_DATA_SUCCESS,
  LOADING_QUOTE_DATA_FINISH,
  LOADING_QUOTE_DATA,
  MODAL_CUSTOMER_WELCOME_VISIBLE_CHANGED,
  MODAL_PET_CO_PAY_VISIBLE_CHANGED,
  MULTI_MODAL_CUSTOM_CONTENT_CHANGED,
  MULTI_MODAL_CUSTOM_CONTENT_CLEARED,
  MULTI_MODAL_NEED_TO_KNOW_VISIBLE_CHANGED,
  MULTI_MODAL_PET_CO_PAY_VISIBLE_CHANGED,
  MULTI_MODAL_TERMS_AND_COND_VISIBLE_CHANGED,
  MULTI_MODAL_WELLNESS_VISIBLE_CHANGED,
  NAME_ON_CARD_CHANGED,
  NAME_ON_CARD_ERROR_CHANGED,
  NEW_CUSTOMER_ADDRESS_LOAD_ATTEMPTED,
  NEW_CUSTOMER_CITY_CHANGED,
  NEW_CUSTOMER_CONFIRM_PASSWORD_CHANGED,
  NEW_CUSTOMER_EMAIL_CHANGED,
  NEW_CUSTOMER_EMAIL_ERROR_CHANGED,
  NEW_CUSTOMER_EMPLOYEE_ID_CHANGED,
  NEW_CUSTOMER_LAST_NAME_CHANGED,
  NEW_CUSTOMER_NAME_CHANGED,
  NEW_CUSTOMER_PASSWORD_CHANGED,
  NEW_CUSTOMER_PASSWORD_CONFIRM_ERROR_CHANGED,
  NEW_CUSTOMER_PASSWORD_ERROR_CHANGED,
  NEW_CUSTOMER_PHONE_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED,
  NEW_CUSTOMER_STATE_CHANGED,
  NEW_CUSTOMER_STREET_CHANGED,
  NEW_CUSTOMER_STREET2_CHANGED,
  NEW_CUSTOMER_ZIP_CODE_CHANGED,
  NEW_PET_AGE_SAVED,
  NEW_PET_BREED_SAVED,
  NEW_PET_EMAIL_SAVED,
  NEW_PET_NAME_SAVED,
  NEW_PET_SEX_SAVED,
  NEW_PET_TYPE_SAVED,
  NEW_PET_ZIPCODE_SAVED,
  NO_DIAMOND_CLIENT_ID_OR_PC_ERROR,
  NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL,
  ONE_INC_PAYMENT_BANK_SAVED,
  ONE_INC_PAYMENT_CREDIT_CARD_SAVED,
  ONE_INC_PAYMENT_DEFAULTS_SAVED,
  ONE_INC_PAYMENT_SAVED,
  ONE_INC_PAYMENT_UPDATED,
  ONE_INC_PET_PAYMENT_METHOD_SAVED,
  ONE_INC_PET_PAYMENT_UPDATED,
  ONE_INC_SESSION_CREATED,
  ONE_INC_SESSION_REMOVED,
  OPEN_QUOTE_LOADED,
  OPEN_QUOTE_PENDING_PETS_LOADED,
  OPEN_SAVED_QUOTE_ATTEMPT,
  OPEN_SAVED_QUOTE_ERROR,
  OPEN_SAVED_QUOTE_EXPIRED,
  PAYMENT_METHOD_TYPE_CHANGE,
  PAYMENT_PLAN_FREQUENCY_CHANGED,
  PET_ADDED,
  PET_QUOTE_REMOVAL_ATTEMPT,
  PET_QUOTE_REMOVAL_FAILED,
  PET_QUOTE_REMOVAL_SUCCEED,
  PET_REMOVED,
  PET_TAG_COLOR_CHANGED,
  PET_TAG_DATE_ERRORS_CHANGED,
  PET_TAG_DATE_OF_BIRTH_CHANGED,
  PET_UPDATED,
  POWER_UP_SELECTED,
  PURCHASE_ATTEMPT,
  PURCHASE_COMPLETED,
  QUOTE_DATA_LOADED_BY_STORAGE,
  QUOTE_PLAN_SELECTED,
  QUOTE_POWER_UP_CHANGED,
  QUOTE_WELLNESS_SELECTED,
  QUOTING_PLAN_DATA_LOADED,
  RATE_QUOTE_ERROR,
  RATE_QUOTE_UPDATE_ATTEMPT,
  RATE_QUOTE_UPDATE_LOADED,
  RATE_SUMMARY_ATTEMP,
  RATE_SUMMARY_FAILED,
  RATE_SUMMARY_SUCCESS,
  RECALCULATE_LOCAL_TOTAL,
  REMOVE_PET_MODAL_VISIBLE,
  RESET_POWER_UP_ARIA_LABEL,
  RESET_QUOTES_VALUES_SELECTED,
  ROUTING_NUMBER_CHANGED,
  ROUTING_NUMBER_ERROR_CHANGED,
  SAME_ADDRESS_FOR_BILLING_CHANGED,
  SAVE_DEDUCTIBLE_SELECTED,
  SAVE_MONTHLY_PLAN_SELECTED,
  SAVE_QUOTE_ATTEMPT,
  SAVE_QUOTE_ERROR,
  SAVE_QUOTE_MOBILE_VISIBLE_CHANGED,
  SAVE_REIMBURSEMENT_SELECTED,
  SAVE_SELECTED_ADD_PET_MODAL,
  SAVE_SELECTED_EDIT_PET_MODAL,
  SAVE_SELECTED_PET_NUMBER,
  SAVE_UPDATED_PET,
  SAVE_URL_PARAMETERS,
  SAVED_QUOTE_MODAL_VISIBLE,
  SAVED_QUOTE,
  SECOND_ADDRESS_CITY_CHANGED,
  SECOND_ADDRESS_CITY_ERROR_CHANGED,
  SECOND_ADDRESS_CLEARED,
  SECOND_ADDRESS_STATE_ERROR_CHANGED,
  SECOND_ADDRESS_STREET_CHANGED,
  SECOND_ADDRESS_STREET_ERROR_CHANGED,
  SECOND_ADDRESS_STREET2_CHANGED,
  SECOND_ADDRESS_ZIP_CODE_CHANGED,
  SECOND_ADDRESS_ZIP_ERROR_CHANGED,
  SECOND_PARENT_FORM_TOGGLED,
  SEND_SMS_APPS_LINKS_ATTEMPT,
  SEND_SMS_APPS_LINKS_FAILED,
  SEND_SMS_APPS_LINKS_SUCCESS,
  SIGNED_UP_SMS_ATTEMPT,
  SIGNED_UP_SMS,
  SUB_ID_SAVED,
  SUB_ID_SYNONYMS_DATA_LOADED,
  TERMS_AND_CONDITION_VALIDATION_CHANGED,
  UNDERSTAND_CONDITIONS_CHANGED,
  VETERINARIAN_SET,
  VETERINARIANS_LOAD_ATTEMPT,
  VETERINARIANS_LOADED,
  YOUR_INFO_SCROLL_TO_TOP_TOGGLED,
  YOUR_INFO_STEP_CHANGED,
  ONE_INC_RESET_ALL,
  QUOTE_SELECTED_VALUES_REMOVED,
  CONTINUE_WITHOUT_LOGIN,
  RECALCULATE_PACKAGED_VALUES_SELECTED,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MESSAGE_NO_DATA,
  CUSTOMER_VALIDATION_RESTARTED,
  RECAPTCHA_VALIDATE_ATTEMPT,
  RECAPTCHA_VALIDATE_LOADED,
  RECAPTCHA_VALIDATE_FAILED,
  RETRY_PURCHASE_ERROR,
  TERMS_AND_POLICY_CHANGED,
  PURCHASE_MULTIPLE_ATTEMPT,
  PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED,
  POWERUP_ID_ERRORS_REMOVED,
  POWERUP_ID_ERRORS_RESTARTED,
  POWERUP_ID_ERRORS_SET,
  CUSTOMER_PASSWORD_SET,
  LOGIN_B2C_STARTED,
  LOGIN_B2C_FAILED,
  DUPLICATED_VALIDATION_FAILED,
  DUPLICATED_VALIDATION_MODAL_CLOSED,
  ALL_PETS_POWERUPS_VALIDATED,
} from '../actions/types';
import {
  AMERICAN_STATES,
  AccountTypes,
  CreditCardType,
  EMPLOYEE_GUID_DUMMY,
  MODAL_DEFAULT_CATEGORY,
  PAYMENT_TYPE,
  PetGender,
  PetType,
  YOUR_INFO_STEPS,
  ONE_INC_EMPTY_ACH,
  ONE_INC_EMPTY_CC,
  ONE_INC_PAYMENT_CATEGORY,
  MARKETING_CHANNEL,
  PURCHASE_MULTI_STATUS,
} from '../constants';

const {
  NEED_TO_KNOW_DEF_CATEGORY_ID,
  PET_CO_PAY_DEF_CATEGORY_ID,
  TERMS_AND_COND_DEF_CATEGORY_ID,
  WELLNESS_DEF_CATEGORY_ID,
} = MODAL_DEFAULT_CATEGORY;

const INITIAL_QUOTE_SELECTED_VALUES = {
  annualAmountPlan: 0,
  annualTax: 0,
  deductibleId: 9,
  deductibleQuantity: '$750',
  isMonthlyPlan: true,
  modifiers: [],
  monthlyAmountPlan: 0,
  monthlyTax: 0,
  petName: '',
  quoteId: 1,
  reimbursementId: 3,
  reimbursementPercentage: '80%',
  // @Deprecate
  selectedPlan: 9,
  selectedPlanAmount: '0',
  selectedPlanIndex: 1,
  selectedPlanPackaged: 10,
  total: 0,
  totalAnnual: 0,
  totalMontly: 0,
};

const INITIAL_SECONDARY_ADDRESS_STATE = {
  city: '',
  cityError: '',
  state: '',
  stateError: '',
  street: '',
  street2: '',
  street2Error: '',
  streetError: '',
  zip: '',
  zipError: '',
};

const INITIAL_PLANS_STATE = [{ id: 0 }, { id: 1 }, { id: 2 }];

const INITIAL_PLANS_PACKAGED = [
  {
    DeductibleId: 8,
    Description: '',
    Id: 4,
    monthlyPrice: 0,
    Name: 'Most Popular',
    PlanId: 9,
    ReimbursementId: 3,
  },
  {
    DeductibleId: 4,
    Description: '',
    Id: 5,
    monthlyPrice: 0,
    Name: 'Higher Coverage',
    PlanId: 39,
    ReimbursementId: 2,
  },
  {
    DeductibleId: 9,
    Description: '',
    Id: 6,
    monthlyPrice: 0,
    Name: 'Value Plus',
    PlanId: 12,
    ReimbursementId: 4,
  },
];

const INITIAL_RATE_SUMMARY = {
  AllDiscounts: [],
  loading: false,
  PetQuotes: [],
  powerUpChanged: false,
  pricesChanged: false,
  QuoteSummaries: [],
};

const INITIAL_ONE_INC = {
  bankAccount: { ...ONE_INC_EMPTY_ACH },
  creditCard: { ...ONE_INC_EMPTY_CC },
  customerId: '',
  customerUpdated: false,
  defaultsPaymentMethod: {
    bankAccount: { ...ONE_INC_EMPTY_ACH },
    creditCard: { ...ONE_INC_EMPTY_CC },
    defaultValue: '',
  },
  isTwoDefaults: false,
  paymentCategory: '',
  petPaymentMethods: [],
  portalOneSessionKey: '',
  tokenId: '',
};

const INITIAL_STATE = {
  acceptedTermsAndPolicy: false,
  addPetModalVisible: false,
  ageData: null,
  americanStates: [],
  breedData: null,
  companyLogo: null,
  companyName: '',
  currentQuoteStep: 1,
  customerValidationLoading: false,
  // FIXME: unused remvove with types
  customerValidationResponse: {
    customerRoles: [],
    displayName: '',
    email: '',
    fullName: '',
    hasPassword: true,
    id: 0,
    isValid: false,
    message: '',
    userName: '',
  },
  customerZipCode: null,
  customVet: {
    city: '',
    nameOfPractice: '',
    state: '',
    stateId: '',
  },
  customVetModalVisible: false,
  data: null,
  deductibleLegend: '',
  diamondClientLoading: false,
  editPetModalVisible: false,
  effectiveDateCustom: null,
  employeeIdHelpText: null,
  employer: {
    id: 1,
  },
  employerId: EMPLOYEE_GUID_DUMMY,
  employerIdError: '',
  employerIdValid: false,
  employerIdValidated: false,
  employerPromoCode: 'promoCode',
  exlusiveOfferMessage: null,
  guidData: null,
  howDidYouHearOptions: null,
  installmentName: '',
  isErrorModalVisible: false,
  isLoading: false,
  isLoadingNewAddress: false,
  isMonthlyPlan: true,
  landingLogo: null,
  liveVetData: null,
  liveVetPrice: null,
  liveVetSubscription: {
    purchaseAttempt: false,
    purchaseError: false,
  },
  liveVetValidations: {
    newCustomerIsValid: false,
    petParentIsValid: false,
  },
  loadingTagFinish: '',
  loggingWithoutModal: false,
  message: '',
  modalCustomerWelcomeVisible: false,
  multiModalCustomData: null,
  multiModalData: {
    InsuranceMultiModalSectionDtoList: [],
    IsEbProduct: false,
    ZipCode: '',
  },
  multiModalUserSelection: {
    needToKnowIdSelected: NEED_TO_KNOW_DEF_CATEGORY_ID,
    needToKnowModalVisible: false,
    needToKnowSelectionHistory: [],
    petCoPayIdSelected: PET_CO_PAY_DEF_CATEGORY_ID,
    petCoPayModalVisible: false,
    termsAndCondIdSelected: TERMS_AND_COND_DEF_CATEGORY_ID,
    termsAndCondModalVisible: false,
    wellnessIdSelected: WELLNESS_DEF_CATEGORY_ID,
    wellnessModalVisible: false,
  },
  newCustomer: {
    address: {
      city: '',
      cityFromZipCode: '',
      state: '',
      stateAbbr: '',
      street: '',
      street2: '',
      zipCode: '',
    },
    canEditAddress: false,
    email: '',
    emailError: '',
    emailValidated: false, // email validate if exist
    employeeID: '',
    lastName: '',
    loadingVets: false,
    name: '',
    password: '',
    passwordConfirm: '',
    passwordConfirmError: '',
    passwordError: '',
    phoneNumber: '',
    secondParent: {
      lastName: '',
      name: '',
      phoneNumber: '',
    },
  },
  newPet: {
    breedCat: [],
    breedDog: [],
    email: '',
    petAge: null,
    petBreed: null,
    petBreedId: null,
    petName: '',
    petSex: PetGender.None,
    petType: PetType.None,
    zipcode: '',
  },
  oneInc: INITIAL_ONE_INC,
  openQuoteGuid: '',
  parameters: {},
  paymentMethod: {
    bankAccount: {
      accountNumber: '',
      accountNumberConfirmation: '',
      accountNumberConfirmationError: '',
      accountNumberError: '',
      accountType: AccountTypes[0],
      routingNumber: '',
      routingNumberError: '',
    },
    billingAddress: INITIAL_SECONDARY_ADDRESS_STATE,
    creditDebit: {
      cardCvv: '',
      cardCvvError: '',
      cardNumber: '',
      cardNumberError: '',
      creditCardType: CreditCardType.Other,
      expirationDate: '',
      expirationDateError: '',
      nameOnCard: '',
      nameOnCardError: '',
    },
    loadingSecondAddress: false,
    mailAddressSameAsPet: true,
    paymentMethodType: PAYMENT_TYPE.creditCard,
  },
  petParentAddress: null,
  petParentAddressError: false,
  petQuoteSelected: 1,
  petRemovedLoading: false,
  petRemovedSuccess: false,
  petsWithQuoteError: [],
  petTags: null,
  petTagsErrors: [],
  petToRemove: {
    petName: '',
    petType: PetType.Dog.value,
  },
  petUpdated: false,
  planChanged: false,
  plans: INITIAL_PLANS_STATE,
  plansPackaged: INITIAL_PLANS_PACKAGED,
  powerUpErrors: [],
  powerUpErrorsScroll: false,
  powerUpLoadings: [],
  purchase: {
    customerId: '',
    duplicatedPets: [],
    duplicatedPetsModalVisible: false,
    error: '',
    incompletePurchases: [],
    loading: false,
    multiQuoteList: [
      {
        id: 0,
        isCat: true,
        petName: '',
        status: PURCHASE_MULTI_STATUS.progress,
      },
    ],
    multiQuoteLoading: false,
    notDuplicatedPets: [],
    policyNumbers: [],
    sendingMessage: false,
    success: false,
  },
  quoteSelectedValues: [],
  rateSummary: INITIAL_RATE_SUMMARY,
  recaptcha: {
    error: null,
    loading: false,
    token: null,
  },
  removePetModalSuccess: false,
  removePetModalVisible: false,
  saveQuote: {
    failOpenQuote: false,
    isOpeningQuote: false,
    isSavingQuote: false,
    openQuoteLoaded: false,
    quoteId: null,
    redirectLink: '',
    savedQuoteExpired: false,
    savedQuoteId: null,
    savedQuoteModalVisible: false,
    saveQuoteMobileModalVisible: false,
  },
  scrollYourInfoToTop: false,
  searchParameters: '',
  secondParentFormVisible: false,
  selectedVeterinarian: {
    AccountID: '0010a00001WKi7Z',
    customCity: '',
    customState: '',
    customStateId: '',
    isCustomVet: false,
    nameOfPractice: '',
  },
  sessionInformation: {
    forgetPasswordEmail: '',
    forgetPasswordEmailError: '',
    forgetPasswordModalConfirmVisible: false,
    forgetPasswordModalVisible: false,
    forgetPasswordPasswordUpdated: false,
    forgetPasswordResetLoading: false,
    isCustomer: false,
    isLoading: false,
    loadingLogin: false,
    nopCommerceUser: {
      Address: {
        AddressLine1: '',
        AddressLine2: '',
        City: '',
        State: '',
        ZipCode: '',
      },
      BillingInfoList: [],
      CustomerNopCommerceId: 0,
      DefaultPaymentProfileIdACH: '',
      DefaultPaymentProfileIdCC: '',
      DiamonClientdId: 0,
      FirstName: '',
      LastName: '',
      LoginMessage: '',
      LoginSuccess: false,
      MarketingChannelId: MARKETING_CHANNEL.Figo,
      MiddleName: '',
    },
    showLoginMenssage: false,
    showWelcomeMessage: false,
    userEmail: '',
  },
  showModalLoginLiveVet: false,
  subId: {},
  subIdSynonyms: [],
  supportPhoneNumber: '',
  temporaryMessageData: '',
  termsAndConditions: {
    agreeTermsAndConditionCheck: false,
    agreeTermsAndConditionError: false,
    consentElectronicDeliveryCheck: true,
    modalDescription: '',
    modalTitle: '',
    understandConditionCheck: false,
    understandConditionError: false,
  },
  textAlerts: {
    error: '',
    loading: false,
    success: false,
  },
  togglePetCoPayModalVisible: false,
  validatingEmployerId: false,
  validatingLiveVetAccess: false,
  vetList: [],
  yourInfoStep: YOUR_INFO_STEPS.firstStep,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RATE_QUOTE_ERROR: {
      return {
        ...state,
        addPetModalVisible: false,
        editPetModalVisible: false,
        isLoading: action.status,
        message: action.message,
        petTags: [],
      };
    }

    case NO_DIAMOND_CLIENT_ID_OR_PC_ERROR:
      return {
        ...state,
        addPetModalVisible: false,
        editPetModalVisible: false,
        isLoading: false,
        message: action.message,
        petTags: [],
        redirectToLanding: true,
      };

    case LOADING_QUOTE_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LOADING_QUOTE_DATA_FINISH: {
      return {
        ...state,
        loadingTagFinish: action.tag || '',
      };
    }

    case QUOTING_PLAN_DATA_LOADED: {
      const { isUpdate } = action;

      return {
        ...state,
        customerZipCode: action.data.zipCode,
        data: action.data,
        isLoading: false,
        newPet: {
          ...state.newPet,
          breedDataFiltered: null,
          petAge: null,
          petName: '',
          petSex: PetGender.None,
          petType: PetType.None,
        },
        openQuoteGuid: action.openQuoteGuid,
        petTags: state.petTags
          ? state.petTags
          : action.data.ebPetQuoteResponseList
            .map((quote) => {
              const { petName, petQuoteId, petType } = quote;

              return {
                petDateOfBirth: '',
                petName,
                petQuoteId,
                petTagColor: 1,
                petType,
              };
            }),
        petUpdated: false,
        plans: action.plans,
        plansPackaged: action.plansPackaged,
        quoteSelectedValues: isUpdate
          ? [
            ...state.quoteSelectedValues.map((item) => {
              if (item.quoteId === state.petQuoteSelected) {
                return {
                  ...item,
                  petName: isUpdate
                    ? action.petName
                    : item.petName,
                };
              }

              return item;
            }),
          ]
          : [
            ...state.quoteSelectedValues,
            {
              ...INITIAL_QUOTE_SELECTED_VALUES,
              annualAmountPlan: action.annualAmountPlan,
              deductibleId: action.deductibleId,
              deductibleQuantity: action.deductibleQuantity,
              modifiers: action.modifiers,
              monthlyAmountPlan: action.monthlyPremium,
              petName: action.petName,
              quoteId: action.quoteId,
              reimbursementId: action.reimbursementId,
              reimbursementPercentage: action.reimbursementPercentage,
              selectedPlan: action.selectedPlan,
              selectedPlanAmount: action.selectedPlanAmount,
              selectedPlanIndex: action.selectedPlanIndex,
              selectedPlanPackaged: action.selectedPlanPackaged
                || action.selectedPlan,
              total: action.monthlyPremium,
              totalAnnual: action.monthlyPremium * 12,
              totalMontly: action.monthlyPremium,
            },
          ],
        saveQuote: {
          ...state.saveQuote,
          isOpeningQuote: false,
        },
      };
    }

    case SAVE_URL_PARAMETERS: {
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          email: action.parameters.email,
        },
        parameters: action.parameters,
        searchParameters: action.search,
      };
    }

    case SAVE_MONTHLY_PLAN_SELECTED: {
      return {
        ...state,
        quoteSelectedValues: [
          ...state.quoteSelectedValues.map((item) => {
            if (item.quoteId === state.petQuoteSelected) {
              return {
                ...item,
                annualAmountPlan: action.annualAmount,
                monthlyAmountPlan: action.monthlyAmount,
              };
            }

            return item;
          }),
        ],
      };
    }

    case SAVE_REIMBURSEMENT_SELECTED: {
      return {
        ...state,
        planChanged: false,
        quoteSelectedValues: [
          ...state.quoteSelectedValues.map((item) => {
            if (item.quoteId === state.petQuoteSelected) {
              return {
                ...item,
                reimbursementId: action.id,
                reimbursementPercentage: action.description,
              };
            }

            return item;
          }),
        ],
      };
    }

    case SAVE_DEDUCTIBLE_SELECTED: {
      return {
        ...state,
        planChanged: false,
        quoteSelectedValues: [
          ...state.quoteSelectedValues.map((item) => {
            if (item.quoteId === state.petQuoteSelected) {
              return {
                ...item,
                deductibleId: action.id,
                deductibleQuantity: action.description,
              };
            }

            return item;
          }),
        ],
      };
    }

    case QUOTE_WELLNESS_SELECTED: {
      return {
        ...state,
        quoteSelectedValues: [
          ...state.quoteSelectedValues.map((item) => {
            if (item.quoteId === state.petQuoteSelected) {
              return {
                ...item,
                wellness: action.quantity,
              };
            }

            return item;
          }),
        ],
      };
    }

    case RECALCULATE_LOCAL_TOTAL: {
      return {
        ...state,
        quoteSelectedValues: [
          ...state.quoteSelectedValues.map((item) => {
            if (item.quoteId === state.petQuoteSelected) {
              return {
                ...item,
                total: item.isMonthlyPlan
                  ? item.monthlyAmountPlan : item.monthlyAmountPlan * 12,
                totalAnnual: item.monthlyAmountPlan * 12,
                totalMontly: item.monthlyAmountPlan,
              };
            }

            return item;
          }),
        ],
      };
    }

    case NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL:
      return {
        ...state,
        quoteSelectedValues: [
          ...state.quoteSelectedValues.map((item) => {
            const quoteValues = state.data.ebPetQuoteResponseList
              .find((quote) => quote.petQuoteId === item.quoteId);
            const plan = quoteValues.Plans[item.selectedPlanIndex];
            const { AnnualPremium, MonthlyPremium } = plan.RatingOptions
              .find((rating) => rating.DeductibleId === item.deductibleId
                && rating.ReimbursementId === item.reimbursementId);

            return {
              ...item,
              annualAmountPlan: AnnualPremium,
              monthlyAmountPlan: MonthlyPremium,
              total: item.isMonthlyPlan ? MonthlyPremium : AnnualPremium,
              totalAnnual: AnnualPremium,
              totalMontly: MonthlyPremium,
            };
          }),
        ],
      };

    // Recalculate Deductible/Reimbursement selected from PrePackagedPlans
    case RECALCULATE_PACKAGED_VALUES_SELECTED:
      return {
        ...state,
        quoteSelectedValues: state.quoteSelectedValues.map((item) => {
          const { selectedPlanPackaged } = item;
          const quote = state.data.ebPetQuoteResponseList
            .find((petQuote) => petQuote.petQuoteId === item.quoteId);
          const prePackagedPlan = quote.PrePackagedPlans
            .find((prePackaged) => prePackaged.Id === selectedPlanPackaged);

          if (!prePackagedPlan) {
            return item;
          }

          const { DeductibleId, PlanId, ReimbursementId } = prePackagedPlan;
          const planSelected = quote.Plans.find((plan) => plan.Plan === PlanId);
          const ratingOption = planSelected.RatingOptions
            .find((ratingItem) => ratingItem.DeductibleId === DeductibleId
              && ratingItem.ReimbursementId === ReimbursementId);

          return {
            ...item,
            deductibleId: DeductibleId,
            deductibleQuantity: ratingOption.DeductibleName,
            reimbursementId: ReimbursementId,
            reimbursementPercentage: ratingOption.ReimbursementName,
            selectedPlan: PlanId,
          };
        }),
      };

    case QUOTE_DATA_LOADED_BY_STORAGE: {
      return {
        ...INITIAL_STATE,
        ...action.data,
        sessionInformation: {
          ...INITIAL_STATE.sessionInformation,
          ...action.data.sessionInformation,
        },
      };
    }

    case GUID_DATA_LOADED:
      return {
        ...state,
        guidData: action.guidData,
        message: '',
      };

    case QUOTE_PLAN_SELECTED:
      return {
        ...state,
        planChanged: true,
        quoteSelectedValues: [
          ...state.quoteSelectedValues.map((item) => {
            if (item.quoteId === state.petQuoteSelected) {
              return {
                ...item,
                selectedPlan: action.planId,
                selectedPlanAmount: action.planAmount,
                selectedPlanIndex: state.plans
                  .findIndex((plan) => plan.id === action.planId),
                selectedPlanPackaged: action.planPackagedId,
              };
            }

            return item;
          }),
        ],
      };

    case DEDUCTIBLE_LEGEND_CHANGED: {
      return {
        ...state,
        deductibleLegend: action.deductibleLegend,
      };
    }

    case EFFECTIVE_DATE_CUSTOM_CHANGED:
      return {
        ...state,
        effectiveDateCustom: action.newDate,
      };

    case CONTINUE_WITHOUT_LOGIN:
      return {
        ...state,
        loggingWithoutModal: !state.loggingWithoutModal,
      };

    case BREED_DATA_LOADED: {
      return {
        ...state,
        breedData: action.data,
        isLoading: false,
        message: '',
        newPet: {
          ...state.newPet,
          breedCat: action.breedCat,
          breedDog: action.breedDog,
        },
      };
    }

    case BREED_DATA_ERROR: {
      return {
        ...state,
        addPetModalVisible: false,
        editPetModalVisible: false,
        isLoading: false,
        message: action.message,
      };
    }

    case AGE_DATA_ERROR: {
      return {
        ...state,
        addPetModalVisible: false,
        editPetModalVisible: false,
        isLoading: false,
        message: action.message,
      };
    }

    case AGE_DATA_LOADED: {
      return {
        ...state,
        ageData: action.data,
        isLoading: false,
        message: '',
      };
    }

    case NEW_PET_AGE_SAVED: {
      return {
        ...state,
        newPet: {
          ...state.newPet,
          petAge: action.petAge,
        },
      };
    }

    case NEW_PET_BREED_SAVED: {
      return {
        ...state,
        newPet: {
          ...state.newPet,
          petBreed: action.petBreed,
          petBreedId: action.petBreedId,
        },
      };
    }

    case NEW_PET_NAME_SAVED: {
      return {
        ...state,
        newPet: {
          ...state.newPet,
          petName: action.petName,
        },
      };
    }

    case NEW_PET_TYPE_SAVED: {
      return {
        ...state,
        newPet: {
          ...state.newPet,
          breedDataFiltered: action.petType === PetType.Dog
            ? state.newPet.breedDog : state.newPet.breedCat,
          petBreed: '',
          petType: action.petType,
        },
      };
    }

    case NEW_PET_ZIPCODE_SAVED: {
      return {
        ...state,
        newPet: {
          ...state.newPet,
          zipcode: action.zipcode,
        },
      };
    }

    case NEW_PET_EMAIL_SAVED: {
      return {
        ...state,
        newPet: {
          ...state.newPet,
          email: action.email,
        },
      };
    }

    case QUOTE_POWER_UP_CHANGED: {
      return {
        ...state,
        data: {
          ...state.data,
          insuranceProductEB: {
            ...state.data.insuranceProductEB,
            InsuranceModifiersEB:
              state.data.insuranceProductEB.InsuranceModifiersEB
                .map((item) => {
                  if (item.Id === action.id) {
                    return {
                      ...item,
                      IsSelected: action.isSelected,
                    };
                  }

                  return item;
                }),
          },
          rateSummary: {
            ...state.rateSummary,
            powerUpChanged: true,
            pricesChanged: true,
          },
        },
      };
    }

    case NEW_PET_SEX_SAVED: {
      return {
        ...state,
        newPet: {
          ...state.newPet,
          petSex: action.petSex,
        },
      };
    }

    case SAVE_SELECTED_PET_NUMBER: {
      return {
        ...state,
        petQuoteSelected: action.petQuoteSelected,
      };
    }

    case RATE_QUOTE_UPDATE_ATTEMPT: {
      return {
        ...state,
        powerUpLoadings: [...state.powerUpLoadings, action.id],
      };
    }

    case RATE_QUOTE_UPDATE_LOADED: {
      return {
        ...state,
        powerUpLoadings: state.powerUpLoadings.filter((id) => id !== action.id),
      };
    }

    case SAVE_SELECTED_EDIT_PET_MODAL: {
      const { petQuoteSelected } = state;
      const { petZipCode } = state.parameters;
      const { petAgeName, breedName, breedId, petName, genderName, petType } =
        state.data.ebPetQuoteResponseList
          .find((item) => item.petQuoteId === petQuoteSelected);

      return {
        ...state,
        editPetModalVisible: action.editPetModalVisible,
        newPet: {
          ...state.newPet,
          petAge: petAgeName,
          petBreed: breedName,
          petBreedId: breedId,
          petName,
          petSex: genderName === PetGender.Male.name
            ? PetGender.Male : PetGender.Female,
          petType: petType === PetType.Dog.value
            ? PetType.Dog : PetType.Cat,
          zipcode: petZipCode,
        },
      };
    }

    case SAVE_SELECTED_ADD_PET_MODAL: {
      const { petZipCode } = state.parameters;

      return {
        ...state,
        addPetModalVisible: action.addPetModalVisible,
        newPet: {
          ...state.newPet,
          petAge: '',
          petBreed: '',
          petBreedId: '',
          petName: '',
          petSex: PetGender.None,
          petType: PetType.None,
          zipcode: petZipCode,
        },
      };
    }

    case REMOVE_PET_MODAL_VISIBLE: {
      const { petName, petType } = state.data.ebPetQuoteResponseList
        .find((item) => item.petQuoteId === state.petQuoteSelected);

      return {
        ...state,
        petRemovedLoading: false,
        petRemovedSuccess: false,
        petToRemove: {
          ...state.petToRemove,
          petName,
          petType,
        },
        removePetModalVisible: action.removePetModalVisible,
      };
    }

    case SAVE_UPDATED_PET: {
      const { petQuoteSelected } = state;
      const {
        petAge,
        petBreed,
        petBreedId,
        petName,
        petSex,
        petType,
        zipcode,
      } = state.newPet;

      return {
        ...state,
        data: {
          ...state.data,
          ebPetQuoteResponseList:
            [
              ...state.data.ebPetQuoteResponseList.map((item) => {
                if (item.petQuoteId === petQuoteSelected) {
                  return {
                    ...item,
                    breedId: petBreedId,
                    breedName: petBreed,
                    genderName: petSex.name,
                    petAgeName: petAge,
                    petName,
                    petType: petType.value,
                  };
                }

                return item;
              }),
            ],
        },
        parameters: {
          ...state.parameters,
          petZipCode: zipcode,
        },
        petUpdated: true,
      };
    }

    case PET_QUOTE_REMOVAL_ATTEMPT: {
      return {
        ...state,
        petRemovedLoading: true,
        petRemovedSuccess: false,
      };
    }

    case PET_QUOTE_REMOVAL_SUCCEED: {
      return {
        ...state,
        petQuoteSelected: action.petQuoteSelected,
        petRemovedLoading: false,
        petRemovedSuccess: true,
        quoteSelectedValues: action.quoteSelectedValues,
      };
    }

    case PET_QUOTE_REMOVAL_FAILED: {
      return {
        ...state,
        petRemovedLoading: false,
        petRemovedSuccess: false,
      };
    }

    case SAVE_QUOTE_ATTEMPT: {
      return {
        ...state,
        saveQuote: {
          ...state.saveQuote,
          isSavingQuote: true,
        },
      };
    }

    case OPEN_SAVED_QUOTE_EXPIRED:
      return {
        ...state,
        saveQuote: {
          ...state.saveQuote,
          isOpeningQuote: false,
          savedQuoteExpired: true,
        },
      };

    case SAVED_QUOTE: {
      return {
        ...state,
        saveQuote: {
          ...state.saveQuote,
          isSavingQuote: false,
          quoteId: action.quoteId ?? state.saveQuote.quoteId,
          savedQuoteModalVisible: !action.isAuto,
          saveQuoteMobileModalVisible: false,
        },
      };
    }

    case SAVE_QUOTE_ERROR: {
      return {
        ...state,
        message: action.message,
        saveQuote: {
          ...state.saveQuote,
          isSavingQuote: false,
        },
      };
    }

    case SAVED_QUOTE_MODAL_VISIBLE: {
      return {
        ...state,
        saveQuote: {
          ...state.saveQuote,
          savedQuoteModalVisible: false,
        },
      };
    }

    case SAVE_QUOTE_MOBILE_VISIBLE_CHANGED: {
      return {
        ...state,
        saveQuote: {
          ...state.saveQuote,
          saveQuoteMobileModalVisible: action.visible,
        },
      };
    }

    case NEW_CUSTOMER_EMAIL_CHANGED: {
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          email: action.value,
          emailValidated: false,
        },
        sessionInformation: {
          ...state.sessionInformation,
          isCustomer: false,
        },
      };
    }

    case NEW_CUSTOMER_EMAIL_ERROR_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          emailError: action.value,
        },
      };

    case NEW_CUSTOMER_PASSWORD_CHANGED: {
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          password: action.value,
        },
      };
    }

    case NEW_CUSTOMER_PASSWORD_ERROR_CHANGED: {
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          passwordError: action.value,
        },
      };
    }

    case NEW_CUSTOMER_CONFIRM_PASSWORD_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          passwordConfirm: action.value,
        },
      };

    case NEW_CUSTOMER_PASSWORD_CONFIRM_ERROR_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          passwordConfirmError: action.value,
        },
      };

    case NEW_CUSTOMER_EMPLOYEE_ID_CHANGED: {
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          employeeID: action.value,
        },
      };
    }

    case OPEN_SAVED_QUOTE_ATTEMPT: {
      return {
        ...state,
        saveQuote: {
          ...state.saveQuote,
          isOpeningQuote: true,
        },
      };
    }

    case OPEN_SAVED_QUOTE_ERROR: {
      return {
        ...state,
        message: action.message,
        saveQuote: {
          ...state.saveQuote,
          failOpenQuote: true,
          isOpeningQuote: false,
          redirectLink: action.redirectLink,
        },
      };
    }

    case SUB_ID_SYNONYMS_DATA_LOADED: {
      return {
        ...state,
        subIdSynonyms: action.data,
      };
    }

    case GET_SUB_ID_SYNONYMS_DATA_FAILED: {
      return {
        ...state,
        message: action.message,
      };
    }

    case SUB_ID_SAVED: {
      return {
        ...state,
        subId: action.subId,
      };
    }

    case PET_ADDED: {
      return {
        ...state,
        petTags: [
          ...state.petTags,
          action.data,
        ],
      };
    }

    case PET_REMOVED: {
      return {
        ...state,
        petTags: state.petTags
          .filter((petTag) => !action.ids.includes(petTag.petQuoteId)),
      };
    }

    case PET_UPDATED: {
      return {
        ...state,
        petTags: state.petTags.map((petTag) => {
          if (petTag.petQuoteId === action.data.petQuoteId) {
            return {
              ...petTag,
              petName: action.data.petName,
              petType: action.data.petType,
            };
          }

          return petTag;
        }),
      };
    }

    case PET_TAG_COLOR_CHANGED: {
      return {
        ...state,
        petTags: state.petTags.map((petTag) => {
          if (petTag.petQuoteId === action.data.petQuoteId) {
            return {
              ...petTag,
              petTagColor: action.data.petTagColor,
            };
          }

          return petTag;
        }),
      };
    }

    case PET_TAG_DATE_ERRORS_CHANGED:
      return {
        ...state,
        petTagsErrors: action.data,
      };

    case PET_TAG_DATE_OF_BIRTH_CHANGED: {
      return {
        ...state,
        petTags: state.petTags.map((petTag) => {
          if (petTag.petQuoteId === action.data.petQuoteId) {
            return {
              ...petTag,
              petDateOfBirth: action.data.petDateOfBirth,
            };
          }

          return petTag;
        }),
      };
    }

    case ADDRESS_BY_ZIP_CODE_LOADED:
      return {
        ...state,
        isLoadingNewAddress: false,
        newCustomer: {
          ...state.newCustomer,
          address: {
            ...state.newCustomer.address,
            city: action.address.CityName,
            cityFromZipCode: action.address.CityName,
            state: action.address.StateName,
            stateAbbr: action.address.StateAbbr,
            zipCode: action.address.ZIPCode,
          },
        },
      };
    case FAILED_TO_LOAD_ADDRESS_BY_ZIP_CODE:
      return {
        ...state,
        isLoadingNewAddress: false,
        petParentAddressError: true,
      };

    case OPEN_QUOTE_LOADED: {
      return {
        ...state,
        saveQuote: {
          ...state.saveQuote,
          openQuoteLoaded: true,
          quoteId: action.quoteId,
          savedQuoteId: action.savedQuoteId,
        },
      };
    }

    case SECOND_PARENT_FORM_TOGGLED:
      return {
        ...state,
        secondParentFormVisible: action.toggledState,
      };

    case NEW_CUSTOMER_CITY_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          address: {
            ...state.newCustomer.address,
            city: action.value,
          },
        },
      };

    case NEW_CUSTOMER_LAST_NAME_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          lastName: action.value,
        },
      };

    case NEW_CUSTOMER_NAME_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          name: action.value,
        },
      };

    case NEW_CUSTOMER_PHONE_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          phoneNumber: action.value,
        },
      };

    case NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          secondParent: {
            ...state.newCustomer.secondParent,
            lastName: action.value,
          },
        },
      };

    case NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          secondParent: {
            ...state.newCustomer.secondParent,
            name: action.value,
          },
        },
      };

    case NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          secondParent: {
            ...state.newCustomer.secondParent,
            phoneNumber: action.value,
          },
        },
      };

    case NEW_CUSTOMER_STATE_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          address: {
            ...state.newCustomer.address,
            state: action.value,
          },
        },
      };

    case NEW_CUSTOMER_STREET_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          address: {
            ...state.newCustomer.address,
            street: action.value,
          },
        },
      };

    case NEW_CUSTOMER_STREET2_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          address: {
            ...state.newCustomer.address,
            street2: action.value,
          },
        },
      };

    case NEW_CUSTOMER_ZIP_CODE_CHANGED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          address: {
            ...state.newCustomer.address,
            zipCode: action.value,
          },
        },
      };

    case NEW_CUSTOMER_ADDRESS_LOAD_ATTEMPTED:
      return {
        ...state,
        isLoadingNewAddress: true,
      };

    case YOUR_INFO_SCROLL_TO_TOP_TOGGLED:
      return {
        ...state,
        scrollYourInfoToTop: action.toggledState,
      };

    case PAYMENT_PLAN_FREQUENCY_CHANGED:
      return {
        ...state,
        isMonthlyPlan: action.isMonthly,
        quoteSelectedValues: state.quoteSelectedValues
          .map((quote) => ({
            ...quote,
            isMonthlyPlan: action.isMonthly,
          })),
      };

    case CURRENT_QUOTE_STEP_CHANGED: {
      return {
        ...state,
        currentQuoteStep: action.currentQuoteStep,
      };
    }

    case OPEN_QUOTE_PENDING_PETS_LOADED: {
      return {
        ...state,
        quoteSelectedValues:
          [
            ...action.pendingPets.map((pendingPet) => ({
              ...INITIAL_QUOTE_SELECTED_VALUES,
              annualAmountPlan: pendingPet.annualAmountPlan,
              deductibleId: pendingPet.deductibleId,
              deductibleQuantity: pendingPet.deductibleQuantity,
              monthlyAmountPlan: pendingPet.total,
              petName: pendingPet.petName,
              quoteId: pendingPet.quoteId,
              reimbursementId: pendingPet.reimbursementId,
              reimbursementPercentage: pendingPet.reimbursementPercentage,
              selectedPlan: pendingPet.selectedPlan,
              selectedPlanAmount: pendingPet.selectedPlanAmount,
              selectedPlanIndex: state.plans
                .findIndex((plan) => plan.id === pendingPet.selectedPlan),
              selectedPlanPackaged: pendingPet.selectedPlanPackage
                || pendingPet.selectedPlan,
              total: pendingPet.total,
              totalAnnual: pendingPet.total * 12,
              totalMontly: pendingPet.total,
            })),
          ],
      };
    }

    case EMPLOYER_ID_VALIDATION_ATTEMPT:
      return { ...state, validatingEmployerId: true };

    case EMPLOYER_ID_VALIDATED:
      return {
        ...state,
        companyLogo: action.companyLogo,
        companyName: action.companyName,
        discountMessage: action.discountMessage,
        employeeIdHelpText: action.employeeIdHelpText,
        employer: { guid: action.guid, id: action.id },
        employerId: action.employerId,
        employerIdError: action.message,
        employerIdValid: action.isValid,
        employerIdValidated: true,
        employerPromoCode: action.promoCode,
        exlusiveOfferMessage: action.exlusiveOfferMessage,
        landingLogo: action.landingLogo || null,
        liveVetPrice: action.liveVetPrice === null ? action.liveVetPrice
          : { ...action.liveVetPrice },
        supportPhoneNumber: action.supportPhoneNumber,
        validatingEmployerId: false,
      };

    case FETCH_MESSAGE_SUCCESS:
      return {
        ...state,
        temporaryMessageData: action.payload,
      };

    case FETCH_MESSAGE_NO_DATA:
      return {
        ...state,
        temporaryMessageData: null,
      };

    case NAME_ON_CARD_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            nameOnCard: action.value,
          },
        },
      };

    case CARD_NUMBER_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            cardNumber: action.value,
          },
        },
      };

    case EXPIRATION_DATE_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            expirationDate: action.value,
          },
        },
      };

    case CARD_CVV_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            cardCvv: action.value,
          },
        },
      };

    case PAYMENT_METHOD_TYPE_CHANGE:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          paymentMethodType: action.value,
        },
      };

    case ACCOUNT_TYPE_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          bankAccount: {
            ...state.paymentMethod.bankAccount,
            accountType: action.value,
          },
        },
      };

    case ROUTING_NUMBER_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          bankAccount: {
            ...state.paymentMethod.bankAccount,
            routingNumber: action.value,
          },
        },
      };

    case ACCOUNT_NUMBER_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          bankAccount: {
            ...state.paymentMethod.bankAccount,
            accountNumber: action.value,
          },
        },
      };

    case CONFIRM_ACCOUNT_NUMBER_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          bankAccount: {
            ...state.paymentMethod.bankAccount,
            accountNumberConfirmation: action.value,
          },
        },
      };

    case ROUTING_NUMBER_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          bankAccount: {
            ...state.paymentMethod.bankAccount,
            routingNumberError: action.value,
          },
        },
      };

    case ACCOUNT_NUMBER_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          bankAccount: {
            ...state.paymentMethod.bankAccount,
            accountNumberError: action.value,
          },
        },
      };

    case CONFIRM_ACCOUNT_NUMBER_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          bankAccount: {
            ...state.paymentMethod.bankAccount,
            accountNumberConfirmationError: action.value,
          },
        },
      };

    case NAME_ON_CARD_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            nameOnCardError: action.value,
          },
        },
      };

    case CARD_NUMBER_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            cardNumberError: action.value,
          },
        },
      };

    case EXPIRATION_DATE_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            expirationDateError: action.value,
          },
        },
      };

    case CARD_CVV_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            cardCvvError: action.value,
          },
        },
      };

    case CREDIT_CARD_TYPE_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          creditDebit: {
            ...state.paymentMethod.creditDebit,
            creditCardType: action.value,
          },
        },
      };

    case LIVE_VET_ACCESS_VALIDATION_ATTEMPTED:
      return { ...state, validatingLiveVetAccess: true };

    case LIVE_VET_ACCESS_RESET:
      return {
        ...state,
        liveVetData: null,
        validatingLiveVetAccess: false,
      };

    case LIVE_VET_ACCESS_VALIDATED:
      return {
        ...state,
        liveVetData: action.liveVetData,
        validatingLiveVetAccess: false,
      };

    case LIVE_VET_ACCESS_VALIDATION_FAILED:
      return { ...state, validatingLiveVetAccess: false };

    case CREATE_LIVE_VET_NEW_ACCOUNT_ATTEMPTED:
      return {
        ...state,
        liveVetData: {
          ...state.liveVetData,
          HasAccess: false,
        },
        newCustomer: {
          ...state.newCustomer,
          email: '',
          password: '',
        },
        sessionInformation: { ...INITIAL_STATE.sessionInformation },
      };

    case YOUR_INFO_STEP_CHANGED:
      return {
        ...state,
        yourInfoStep: action.step,
      };

    case CUSTOMER_VALIDATION_ATTEMPT:
      return {
        ...state,
        customerValidationLoading: true,
      };

    case CREATE_DIAMOND_CLIENT_ATTEMPT:
      return {
        ...state,
        diamondClientLoading: true,
      };

    case CREATE_DIAMOND_CLIENT_ERROR:
      return {
        ...state,
        diamondClientLoading: false,
        message: action.message,
      };

    case CREATE_DIAMOND_CLIENT_SUCCESS:
      return {
        ...state,
        diamondClientLoading: false,
        sessionInformation: {
          ...state.sessionInformation,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            DiamonClientdId: action.id,
          },
        },
      };

    case CUSTOMER_VALIDATED:
      return {
        ...state,
        customerValidationLoading: false,
        customerValidationResponse: {
          ...state.customerValidationResponse,
          customerRoles: action.customerRoles,
          displayName: action.displayName,
          email: action.email,
          fullName: action.fullName,
          hasPassword: action.hasPassword,
          id: action.id,
          isValid: true,
          message: '',
          userName: action.userName,
        },
      };

    case CUSTOMER_VALIDATION_ERROR:
      return {
        ...state,
        addPetModalVisible: false,
        customerValidationLoading: false,
        customerValidationResponse: {
          ...state.customerValidationResponse,
          isValid: false,
          message: action.message,
        },
        editPetModalVisible: false,
      };

    case CUSTOMER_VALIDATION_RESTARTED:
      return {
        ...state,
        customerValidationResponse: {
          ...INITIAL_STATE.customerValidationResponse,
          customerRoles: [],
          email: action.email,
        },
      };

    case ERROR_MODAL_CLOSED:
      return {
        ...state,
        message: '',
      };

    case VETERINARIANS_LOAD_ATTEMPT:
      return {
        ...state,
        loadingVets: true,
      };

    case VETERINARIANS_LOADED:
      return {
        ...state,
        loadingVets: false,
        vetList: action.veterinarians,
      };

    case FAILED_TO_LOAD_VETERINARIANS:
      return {
        ...state,
        loadingVets: false,
      };

    case CUSTOM_VET_CITY_CHANGED:
      return {
        ...state,
        customVet: {
          ...state.customVet,
          city: action.value,
        },
      };

    case CUSTOM_VET_PRACTICE_NAME_CHANGED:
      return {
        ...state,
        customVet: {
          ...state.customVet,
          nameOfPractice: action.value,
        },
      };

    case CUSTOM_VET_DATA_CLEARED:
      return {
        ...state,
        customVet: {
          ...INITIAL_STATE.customVet,
        },
        customVetModalVisible: false,
      };

    case VETERINARIAN_SET:
      return {
        ...state,
        selectedVeterinarian: {
          ...action.veterinarian,
          isCustomVet: false,
        },
      };

    case AMERICAN_STATES_LOADED: {
      const formattedStates = action.americanStates.map((item) => ({
        label: item.StateName,
        value: item.Id,
      }));

      const userState = formattedStates.find((americanState) => (
        americanState.value === state.newCustomer.address.stateAbbr
      ));

      return {
        ...state,
        americanStates: formattedStates,
        customVet: {
          ...state.customVet,
          state: userState?.label,
          stateId: userState?.value,
        },
      };
    }

    case FAILED_TO_LOAD_AMERICAN_STATES: {
      const formattedStates = AMERICAN_STATES.map((item) => ({
        label: item.StateName,
        value: item.Id,
      }));

      const userState = formattedStates.find((americanState) => (
        americanState.value === state.newCustomer.address.stateAbbr
      ));

      return {
        ...state,
        americanStates: formattedStates,
        customVet: {
          ...state.customVet,
          state: userState.label,
          stateId: userState.value,
        },
      };
    }

    case CUSTOM_VET_SAVED:
      return {
        ...state,
        customVet: {
          ...INITIAL_STATE.customVet,
        },
        selectedVeterinarian: {
          customCity: action.city,
          customState: action.state,
          customStateId: action.stateId,
          isCustomVet: true,
          nameOfPractice: action.vetPractice,
        },
      };

    case CUSTOM_VET_STATE_CHANGED:
      return {
        ...state,
        customVet: {
          ...state.customVet,
          state: action.value.state,
          stateId: action.value.stateId,
        },
      };

    case CUSTOM_VET_MODAL_OPENED: {
      const { americanStates, newCustomer } = state;
      let userState = americanStates.find((americanState) => (
        americanState.value === newCustomer.address.stateAbbr
      ));

      if (!userState && americanStates.length > 0) {
        [userState] = americanStates;
      }

      return {
        ...state,
        customVet: {
          ...state.customVet,
          state: userState?.label,
          stateId: userState?.value,
        },
        customVetModalVisible: true,
      };
    }

    case RATE_SUMMARY_ATTEMP:
      return {
        ...state,
        rateSummary: {
          ...state.rateSummary,
          loading: true,
          pricesChanged: false,
        },
      };

    case RATE_SUMMARY_FAILED:
      return {
        ...state,
        rateSummary: {
          ...state.rateSummary,
          loading: false,
          pricesChanged: false,
        },
      };

    case RATE_SUMMARY_SUCCESS:
      return {
        ...state,
        rateSummary: {
          ...state.rateSummary,
          loading: false,
          pricesChanged: true,
          ...action.data,
        },
      };

    case RESET_QUOTES_VALUES_SELECTED:
      return {
        ...INITIAL_STATE,
        ageData: state.ageData,
        breedData: state.breedData,
      };

    case PURCHASE_ATTEMPT:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          duplicatedPets: [],
          loading: true,
          notDuplicatedPets: [],
        },
      };

    case PURCHASE_MULTIPLE_ATTEMPT:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          duplicatedPets: [],
          multiQuoteList: action.data,
          multiQuoteLoading: true,
          notDuplicatedPets: [],
        },
      };

    case PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          multiQuoteList: state.purchase.multiQuoteList.map((quote) => {
            if (action.id === quote.id) {
              return { ...quote, status: action.status };
            }

            return quote;
          }),
        },
      };

    case PURCHASE_COMPLETED:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          customerId: action.customerId || state.purchase.customerId,
          error: action.error,
          incompletePurchases: action.incompletePurchases
            || state.purchase.incompletePurchases,
          loading: false,
          multiQuoteLoading: false,
          policyNumbers: action.policyNumbers || state.purchase.policyNumbers,
          sendingMessage: false,
          success: action.success,
        },
      };

    case AGREE_TERMS_AND_CONDITION_CHANGED: {
      const value = !state.termsAndConditions.agreeTermsAndConditionCheck;
      const error = value ? false
        : state.termsAndConditions.agreeTermsAndConditionError;

      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          agreeTermsAndConditionCheck: value,
          agreeTermsAndConditionError: error,
        },
      };
    }

    case UNDERSTAND_CONDITIONS_CHANGED: {
      const value = !state.termsAndConditions.understandConditionCheck;
      const error = value ? false
        : state.termsAndConditions.understandConditionError;
      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          understandConditionCheck: value,
          understandConditionError: error,
        },
      };
    }

    case CONSENT_ELECTRONIC_DELIVERY_CHANGED:
      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          consentElectronicDeliveryCheck:
            !state.termsAndConditions.consentElectronicDeliveryCheck,
        },
      };

    case TERMS_AND_CONDITION_VALIDATION_CHANGED:
      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          agreeTermsAndConditionError:
            !state.termsAndConditions.agreeTermsAndConditionCheck,
          understandConditionError:
            !state.termsAndConditions.understandConditionCheck,
        },
      };

    case SECOND_ADDRESS_CITY_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            city: action.value,
            cityError: '',
          },
        },
      };

    case SECOND_ADDRESS_CLEARED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: INITIAL_SECONDARY_ADDRESS_STATE,
        },
      };

    case SECOND_ADDRESS_STREET_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            street: action.value,
            streetError: '',
          },
        },
      };

    case SECOND_ADDRESS_STREET2_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            street2: action.value,
            street2Error: '',
          },
        },
      };

    case SECOND_ADDRESS_ZIP_CODE_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            city: '',
            state: '',
            zip: action.value,
            zipError: '',
          },
        },
      };

    case SECOND_ADDRESS_STREET_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            streetError: action.value,
          },
        },
      };

    case SECOND_ADDRESS_ZIP_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            zipError: action.value,
          },
        },
      };

    case SECOND_ADDRESS_CITY_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            cityError: action.value,
          },
        },
      };

    case SECOND_ADDRESS_STATE_ERROR_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          billingAddress: {
            ...state.paymentMethod.billingAddress,
            stateError: action.value,
          },
        },
      };

    case SAME_ADDRESS_FOR_BILLING_CHANGED:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          mailAddressSameAsPet: !state.paymentMethod.mailAddressSameAsPet,
        },
      };

    case SEND_SMS_APPS_LINKS_SUCCESS:
    case SEND_SMS_APPS_LINKS_FAILED:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          sendingMessage: false,
        },
      };

    case SEND_SMS_APPS_LINKS_ATTEMPT:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          sendingMessage: true,
        },
      };

    case LOAD_MULTI_MODAL_DATA_FAILED:
      return {
        ...state,
        message: action.message,
      };

    case LOAD_MULTI_MODAL_DATA_SUCCESS:
      return {
        ...state,
        multiModalData: action.data,
      };

    case MODAL_PET_CO_PAY_VISIBLE_CHANGED:
      return {
        ...state,
        togglePetCoPayModalVisible: action.visible,
      };

    case MULTI_MODAL_PET_CO_PAY_VISIBLE_CHANGED:
      return {
        ...state,
        multiModalUserSelection: {
          ...state.multiModalUserSelection,
          petCoPayIdSelected: action.categoryId,
          petCoPayModalVisible: action.visible,
        },
      };

    case MULTI_MODAL_NEED_TO_KNOW_VISIBLE_CHANGED: {
      const { categoryId, visible } = action;
      const newSelectionHistory =
        state.multiModalUserSelection.needToKnowSelectionHistory;

      if (newSelectionHistory.indexOf(categoryId) === -1) {
        newSelectionHistory.push(categoryId);
      }

      return {
        ...state,
        multiModalUserSelection: {
          ...state.multiModalUserSelection,
          needToKnowIdSelected: categoryId,
          needToKnowModalVisible: visible,
          needToKnowSelectionHistory: newSelectionHistory,
        },
      };
    }

    case MULTI_MODAL_WELLNESS_VISIBLE_CHANGED:
      return {
        ...state,
        multiModalUserSelection: {
          ...state.multiModalUserSelection,
          wellnessIdSelected: action.categoryId,
          wellnessModalVisible: action.visible,
        },
      };

    case MULTI_MODAL_TERMS_AND_COND_VISIBLE_CHANGED:
      return {
        ...state,
        multiModalUserSelection: {
          ...state.multiModalUserSelection,
          termsAndCondIdSelected: action.categoryId,
          termsAndCondModalVisible: action.visible,
        },
      };

    case MULTI_MODAL_CUSTOM_CONTENT_CHANGED:
      return {
        ...state,
        multiModalCustomData: {
          ...state.multiModalCustomData,
          category: [{
            CategoryHeader: action.category,
            Id: -1,
          }],
          content: action.content,
          title: action.title,
        },
      };

    case MULTI_MODAL_CUSTOM_CONTENT_CLEARED:
      return {
        ...state,
        multiModalCustomData: null,
      };

    case LIVE_VET_SUBSCRIPTION_ATTEMPT:
      return {
        ...state,
        liveVetSubscription: {
          ...state.liveVetSubscription,
          purchaseAttempt: true,
        },
      };

    case LIVE_VET_SUBSCRIPTION_FAILED:
      return {
        ...state,
        liveVetSubscription: {
          ...state.liveVetSubscription,
          purchaseAttempt: false,
          purchaseError: true,
        },
      };

    case LIVE_VET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        liveVetSubscription: {
          ...state.liveVetSubscription,
          purchaseAttempt: false,
          purchaseError: false,
        },
      };

    case LIVE_VET_SUBSCRIPTION_RESET:
      return {
        ...state,
        liveVetSubscription: {
          ...state.liveVetSubscription,
          purchaseAttempt: false,
          purchaseError: false,
        },
      };

    case RESET_POWER_UP_ARIA_LABEL:
      return {
        ...state,
        rateSummary: {
          ...state.rateSummary,
          powerUpChanged: false,
        },
      };

    case POWER_UP_SELECTED: {
      const { id, isSelected, quoteId } = action;

      const oldModifiers = state.quoteSelectedValues
        .find((quote) => quote.quoteId === quoteId).modifiers || [];
      const isNewModifier =
        !oldModifiers.find((oldModifier) => oldModifier.id === id);

      if (isNewModifier) {
        return {
          ...state,
          planChanged: false,
          quoteSelectedValues: state.quoteSelectedValues.map((item) => {
            if (item.quoteId === quoteId) {
              return {
                ...item,
                modifiers: [
                  ...item.modifiers,
                  { id, isSelected },
                ],
              };
            }

            return item;
          }),
          rateSummary: {
            ...state.rateSummary,
            powerUpChanged: true,
            pricesChanged: true,
          },
        };
      }

      return {
        ...state,
        planChanged: false,
        quoteSelectedValues: state.quoteSelectedValues.map((item) => {
          if (item.quoteId === quoteId) {
            return {
              ...item,
              modifiers: item.modifiers.map((modifier) => {
                if (modifier.id === id) {
                  return { id, isSelected };
                }

                return modifier;
              }),
            };
          }

          return item;
        }),
        rateSummary: {
          ...state.rateSummary,
          powerUpChanged: true,
          pricesChanged: true,
        },
      };
    }

    case FORGET_PASSWORD_MODAL_VISIBLE_CHANGED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          forgetPasswordEmail: '',
          forgetPasswordEmailError: '',
          forgetPasswordModalVisible: action.visible,
        },
      };

    case FORGET_PASSWORD_RESET_PASSWORD_ATTEMTPED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          forgetPasswordEmailError: '',
          forgetPasswordResetLoading: true,
        },
      };

    case FORGET_RESET_PASSWORD_SUCCEEDED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          forgetPasswordEmailError: '',
          forgetPasswordModalConfirmVisible: true,
          forgetPasswordModalVisible: false,
          forgetPasswordPasswordUpdated: true,
          forgetPasswordResetLoading: false,
        },
      };

    case FORGET_RESET_PASSWORD_FAILED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          forgetPasswordEmailError: action.message,
          forgetPasswordPasswordUpdated: false,
          forgetPasswordResetLoading: false,
        },
      };

    case FORGET_PASSWORD_CONFIRM_MODAL_VISIBLE_CHANGED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          forgetPasswordModalConfirmVisible: action.visible,
        },
      };

    case FORGET_PASSWORD_MODAL_EMAIL_CHANGED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          forgetPasswordEmail: action.email,
        },
      };

    case FORGET_PASSWORD_MODAL_EMAIL_ERROR_CHANGED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          forgetPasswordEmailError: action.error,
        },
      };

    case DIAMOND_CUSTOMER_VALIDATION_ATTEMPT:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          emailValidated: false,
        },
        sessionInformation: {
          ...state.sessionInformation,
          isLoading: true,
        },
      };

    case DIAMOND_CUSTOMER_VALIDATION_FAILED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          isLoading: false,
        },
      };

    case DIAMOND_CUSTOMER_VALIDATION_LOADED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          emailValidated: true,
        },
        sessionInformation: {
          ...state.sessionInformation,
          isCustomer: action.isCustomer,
          isLoading: false,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
          },
          userEmail: action.userEmail,
        },
      };

    case LOGIN_B2C_STARTED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          loadingLogin: true,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            LoginMessage: '',
          },
        },
      };

    case LOGIN_B2C_FAILED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          loadingLogin: false,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            LoginMessage: '',
          },
        },
      };

    case DIAMOND_CUSTOMER_LOGIN_ATTEMPT:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          emailValidated: false,
        },
        sessionInformation: {
          ...state.sessionInformation,
          loadingLogin: true,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            LoginMessage: '',
          },
        },
      };

    case DIAMOND_CUSTOMER_LOGIN_SUCCESS:
      return {
        ...state,
        customerZipCode: action.zipCode || state.customerZipCode,
        newCustomer: {
          ...state.newCustomer,
          address: {
            ...state.newCustomer.address,
            city: action.data.Address.City,
            state: action.data.Address.State || state.newCustomer.state,
            stateAbbr: action.data.Address.State || state.newCustomer.state,
            street: action.data.Address.AddressLine1,
            street2: action.data.Address.AddressLine2,
            zipCode: action.data.Address.ZipCode || state.customerZipCode,
          },
          canEditAddress: action.data.CanEditAddress,
          email: action.email,
          emailValidated: true,
          lastName: action.data.LastName,
          name: `${action.data.FirstName} ${action.data.MiddleName}`.trim(),
          password: action.password,
          passwordConfirm: action.password,
        },
        sessionInformation: {
          ...state.sessionInformation,
          isCustomer: true,
          loadingLogin: false,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            ...action.data,
            Address: {
              ...state.sessionInformation.nopCommerceUser.Address,
              ...action.data.Address,
              ZipCode: action.zipCode || state.customerZipCode,
            },
            BillingInfoList: action.data.BillingInfoList,
            DefaultPaymentProfileIdACH: action.data.DefaultPaymentProfileIdACH,
            DefaultPaymentProfileIdCC: action.data.DefaultPaymentProfileIdCC,
            MarketingChannelId: action.data.MarketingChannelId,
          },
          showWelcomeMessage: true,
          userEmail: action.email,
        },
      };

    case DIAMOND_CUSTOMER_LOGOUT:
      return {
        ...state,
        newCustomer: {
          ...INITIAL_STATE.newCustomer,
          phoneNumber: state.newCustomer.phoneNumber,
        },
        sessionInformation: {
          ...state.sessionInformation,
          loadingLogin: false,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            LoginMessage: action.message,
          },
          showWelcomeMessage: false,
        },
      };

    case DIAMOND_CUSTOMER_LOGIN_FAILED:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          loadingLogin: false,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            LoginMessage: action.message,
          },
          showWelcomeMessage: false,
        },
      };

    case DIAMOND_CUSTOMER_RESETED_LOGIN_FAIL:
      return {
        ...state,
        sessionInformation: {
          ...state.sessionInformation,
          nopCommerceUser: {
            ...state.sessionInformation.nopCommerceUser,
            LoginMessage: '',
          },
        },
      };

    case DIAMOND_CUSTOMER_ZIPCODE_CHANGED:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          petZipCode: action.zipCode,
        },
        sessionInformation: {
          ...state.sessionInformation,
          showWelcomeMessage: false,
        },
      };

    case DIAMOND_CUSTOMER_LOGIN_CLEANED:
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          email: '',
          emailValidated: false,
          password: '',
        },
        sessionInformation: { ...INITIAL_STATE.sessionInformation },
      };

    case LIVE_VET_MODAL_LOGIN_VISIBLE_CHANGED:
      return {
        ...state,
        showModalLoginLiveVet: action.visible,
      };

    case LIVE_VET_PET_PARENT_VALIDATED:
      return {
        ...state,
        liveVetValidations: {
          ...state.liveVetValidations,
          petParentIsValid: action.isValid,
        },
      };

    case LIVE_VET_NEW_CUSTOMER_VALIDATED:
      return {
        ...state,
        liveVetValidations: {
          ...state.liveVetValidations,
          newCustomerIsValid: action.isValid,
        },
      };

    case MODAL_CUSTOMER_WELCOME_VISIBLE_CHANGED:
      return {
        ...state,
        modalCustomerWelcomeVisible: action.visible,
      };

    case ONE_INC_SESSION_CREATED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          portalOneSessionKey: action.portalOneSessionKey,
        },
      };

    case ONE_INC_SESSION_REMOVED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          portalOneSessionKey: '',
        },
      };

    case ONE_INC_PAYMENT_CREDIT_CARD_SAVED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          bankAccount: { ...INITIAL_ONE_INC.bankAccount },
          creditCard: action.data,
          paymentCategory: ONE_INC_PAYMENT_CATEGORY.creditCard,
          tokenId: action.data.tokenId,
        },
        paymentMethod: {
          ...state.paymentMethod,
          paymentMethodType: PAYMENT_TYPE.creditCard,
        },
      };

    case ONE_INC_PAYMENT_BANK_SAVED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          bankAccount: action.data,
          creditCard: { ...INITIAL_ONE_INC.creditCard },
          paymentCategory: ONE_INC_PAYMENT_CATEGORY.bankAccount,
          tokenId: action.data.tokenId,
        },
        paymentMethod: {
          ...state.paymentMethod,
          paymentMethodType: PAYMENT_TYPE.bank,
        },
      };

    case ONE_INC_PAYMENT_SAVED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          ...action.data,
          isTwoDefaults: true,
        },
        paymentMethod: {
          ...state.paymentMethod,
          paymentMethodType: PAYMENT_TYPE.bank,
        },
      };

    case ONE_INC_PAYMENT_DEFAULTS_SAVED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          defaultsPaymentMethod: {
            bankAccount: action.data.bankAccount || INITIAL_ONE_INC.bankAccount,
            creditCard: action.data.creditCard || INITIAL_ONE_INC.creditCard,
            defaultValue: action.data.defaultValue,
          },
        },
      };

    case ONE_INC_PAYMENT_UPDATED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          paymentCategory: action.data,
        },
      };

    case ONE_INC_PET_PAYMENT_METHOD_SAVED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          petPaymentMethods: action.data,
        },
      };

    case ONE_INC_PET_PAYMENT_UPDATED:
      return {
        ...state,
        oneInc: {
          ...state.oneInc,
          customerUpdated: action.data,
        },
      };

    case ONE_INC_RESET_ALL:
      return {
        ...state,
        oneInc: {
          ...INITIAL_ONE_INC,
          petPaymentMethods: state.oneInc.petPaymentMethods,
        },
        paymentMethod: {
          ...INITIAL_STATE.paymentMethod,
        },
      };

    case SIGNED_UP_SMS:
      return {
        ...state,
        textAlerts: {
          error: action.error,
          loading: false,
          success: action.success,
        },
      };

    case SIGNED_UP_SMS_ATTEMPT:
      return {
        ...state,
        textAlerts: {
          error: '',
          loading: true,
          success: false,
        },
      };

    case QUOTE_SELECTED_VALUES_REMOVED:
      return {
        ...state,
        quoteSelectedValues: state.quoteSelectedValues
          .filter((quoteValue) => quoteValue.quoteId !== action.petQuoteId),
      };

    case RECAPTCHA_VALIDATE_ATTEMPT: {
      return {
        ...state,
        recaptcha: {
          error: null,
          loading: true,
          token: null,
        },
      };
    }

    case RECAPTCHA_VALIDATE_LOADED: {
      return {
        ...state,
        recaptcha: {
          error: null,
          loading: false,
          token: action.token,
        },
      };
    }

    case RECAPTCHA_VALIDATE_FAILED: {
      return {
        ...state,
        recaptcha: {
          error: action.error,
          loading: false,
          token: null,
        },
      };
    }

    case RETRY_PURCHASE_ERROR: {
      return {
        ...state,
        message: action.message,
      };
    }

    case TERMS_AND_POLICY_CHANGED: {
      return {
        ...state,
        acceptedTermsAndPolicy: action.newStatus,
      };
    }

    case POWERUP_ID_ERRORS_REMOVED: {
      return {
        ...state,
        powerUpErrors: state.powerUpErrors
          .filter((modifierId) => modifierId !== action.modifierId),
        powerUpErrorsScroll: false,
      };
    }
    case POWERUP_ID_ERRORS_RESTARTED: {
      return {
        ...state,
        powerUpErrors: [],
        powerUpErrorsScroll: false,
      };
    }
    case POWERUP_ID_ERRORS_SET: {
      return {
        ...state,
        powerUpErrors: action.modifierIds,
        powerUpErrorsScroll: true,
      };
    }
    case CUSTOMER_PASSWORD_SET: {
      return {
        ...state,
        customerValidationResponse: {
          ...state.customerValidationResponse,
          hasPassword: action.value ?? true,
        },
      };
    }

    case DUPLICATED_VALIDATION_FAILED: {
      return {
        ...state,
        purchase: {
          ...state.purchase,
          duplicatedPets: action.data.duplicatedPets,
          duplicatedPetsModalVisible: true,
          loading: false,
          multiQuoteLoading: false,
          notDuplicatedPets: action.data.notDuplicatedPets,
        },
      };
    }

    case DUPLICATED_VALIDATION_MODAL_CLOSED: {
      return {
        ...state,
        purchase: {
          ...state.purchase,
          duplicatedPetsModalVisible: false,
        },
      };
    }

    case ALL_PETS_POWERUPS_VALIDATED: {
      return {
        ...state,
        petsWithQuoteError: action.petsWithQuoteError || [],
      };
    }

    default:
      return { ...state };
  }
}
