import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  usePetQuoteDropdown,
} from '../../mobile/petQuotingDropDown/hooks/usePetQuoteDropdown';
import { currencyFormat, delay, validatePowerupsSelected } from '../../../util';
import {
  restartPowerupErrors,
  saveSelectedPetNumber,
  setPowerUpErrors,
  validateAllPetsPowerups,
} from '../../../actions';
import {
  useCallbackAfterRate,
  usePrevious,
  useResponsive,
  useSavedElement,
  useVisibilityObserver,
} from '../../../hooks';
import { AFTER_RATE_TAG } from '../../../constants';

import infoIcon from '../../../assets/error.svg';
import editIcon from '../../../assets/pencil.svg';
import arrowEnabled from '../../../assets/ddPetSelector/left-enabled.svg';
import arrowDisabled from '../../../assets/ddPetSelector/right-disabled.svg';

import './PetSelector.css';

const TAB_KEY = 9;
const ENTER_KEY = 13;
const ESC_KEY = 27;
const SPACE_KEY = 32;
const END_KEY = 35;
const HOME_KEY = 36;
const UP_KEY = 38;
const DOWN_KEY = 40;

const MARGIN_TO_ALIGN_TWO = 28;
const MARGIN_TO_ALIGN = 9;

const ActionItem = ({
  id = '',
  label = '',
  focused = false,
  onAction = () => { },
  onKeyDown = () => { },
}) => (
  <li
    className={'Pet-selector-item-menu-item'
      + `${focused ? ' Pet-selector-item-menu-item-hover' : ''}`}
    id={id}
    onClick={onAction}
    onKeyDown={onKeyDown}
    role="menuitem"
  >
    {label}
  </li>
);

const PetDot = ({ petQuote, selected = false }) => {
  const powerUpErrors = useSelector(({ quoting }) => quoting.powerUpErrors);
  const {
    petsWithQuoteError,
  } = usePetQuoteDropdown({ petQuote });
  const hasError = useMemo(() => {
    if (selected) {
      return powerUpErrors.length > 0;
    }
    return petsWithQuoteError.includes(petQuote.petQuoteId);
  }, [petQuote.petQuoteId, petsWithQuoteError, powerUpErrors, selected]);

  return (
    <div
      className="Pet-selector-dot"
      data-error={hasError}
      data-selected={selected}
    />
  );
};

const PetSelectorItem = ({
  disabled = false,
  isSelectedPetValid = () => { },
  isSinglePet = false,
  onPetSelected = () => { },
  petQuote,
  petsCount,
  selected = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [itemSelectedId, setItemSelectedId] = useState(null);
  const {
    quoteSelected,
    onEditPet,
    onRemovePetAskConfirmation,
    petsWithQuoteError,
  } = usePetQuoteDropdown({ petQuote });
  const powerUpErrors = useSelector(({ quoting }) => quoting.powerUpErrors);
  const { t } = useTranslation('common');
  const listRef = useRef();
  const menuButtonRef = useRef();
  const selectedActionRef = useRef();
  const itemRef = useRef();
  const petTabRef = useRef();
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();
  const isVisible = useVisibilityObserver(itemRef);
  const prevState = usePrevious(selected);
  const { saveElementById } = useSavedElement();

  const petQuoteTotal = useMemo(() => {
    const quoteTotal = quoteSelected.totalMontly || 0;
    return `$${currencyFormat({ value: quoteTotal })}`;
  }, [quoteSelected]);

  useEffect(() => {
    // ios workaround
    const scrollIntoView = async () => {
      await delay(50);

      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    };

    if (prevState !== selected && selected && !isVisible && isMobile) {
      itemRef.current.scrollIntoView(false);
      scrollIntoView();
    }
  }, [isMobile, isVisible, petQuote.petQuoteId, prevState, selected]);

  const handleFocus = useCallback(() => {
    if (!isVisible && isMobile) {
      itemRef.current.scrollIntoView(false);
    }
  }, [isMobile, isVisible]);

  useEffect(() => {
    if (isExpanded) {
      listRef.current.focus();
    } else if (menuButtonRef.current) {
      menuButtonRef.current.focus();
    }
  }, [isExpanded]);

  const setActiveDescendant = (newItem) => {
    const newId = newItem.getAttribute('id');
    listRef.current.setAttribute('aria-activedescendant', newId);
    selectedActionRef.current = newItem;
    setItemSelectedId(newId);
  };

  const handleOpen = useCallback(() => {
    setIsExpanded(true);
    listRef.current.focus();
  }, []);

  const handleClose = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const goFirstItem = useCallback(() => {
    if (listRef.current.childNodes.length) {
      const firstItem = listRef.current.childNodes[0];
      setActiveDescendant(firstItem);
    }
  }, []);

  const goLastItem = useCallback(() => {
    const { length } = listRef.current.childNodes;
    if (length) {
      const firstItem = listRef.current.childNodes[length - 1];
      setActiveDescendant(firstItem);
    }
  }, []);

  const handleMenuClick = useCallback(() => {
    handleFocus();
    handleOpen();
    goFirstItem();
  }, [goFirstItem, handleFocus, handleOpen]);

  const handlePetKeyDown = useCallback((event) => {
    const { keyCode } = event;
    switch (keyCode) {
      case DOWN_KEY:
      case SPACE_KEY:
      case ENTER_KEY:
        event.preventDefault();
        handleOpen();
        goFirstItem();
        break;
      case UP_KEY:
        event.preventDefault();
        handleOpen();
        goLastItem();
        break;
      default:
        break;
    }
  }, [goFirstItem, goLastItem, handleOpen]);

  const itemId = `pet-item-${petQuote.petQuoteId}`;
  const petTabId = `pet-tab-${petQuote.petQuoteId}`;
  const petInfoId = `pet-${petQuote.petQuoteId}`;
  const menuIconId = `menuIcon-${petQuote.petQuoteId}`;
  const menuListId = `menu-${petQuote.petQuoteId}`;
  const selectActionId = `menuitem-01-${petQuote.petQuoteId}`;
  const editActionId = `menuitem-02-${petQuote.petQuoteId}`;
  const removeActionId = `menuitem-03-${petQuote.petQuoteId}`;

  const onPowerupsValid = useCallback((callback) => {
    dispatch(validateAllPetsPowerups());
    if (isSelectedPetValid()) {
      callback();
    }
    handleClose();
  }, [dispatch, handleClose, isSelectedPetValid]);

  const handleSelection = useCallback(() => {
    onPetSelected(petQuote);
    handleClose();
  }, [handleClose, onPetSelected, petQuote]);

  const handleAction = useCallback((actionId) => () => {
    switch (actionId) {
      case selectActionId:
        onPowerupsValid(handleSelection);
        break;
      case editActionId:
        onPowerupsValid(onEditPet);
        saveElementById(petInfoId);
        break;
      case removeActionId:
        onRemovePetAskConfirmation();
        saveElementById(petInfoId);
        break;
      default:
        break;
    }
    handleClose();
  }, [
    editActionId,
    handleClose,
    handleSelection,
    onEditPet,
    onPowerupsValid,
    onRemovePetAskConfirmation,
    petInfoId,
    removeActionId,
    saveElementById,
    selectActionId,
  ]);

  const actions = useMemo(() => {
    const editAction = {
      id: editActionId,
      label: t('petSelector.edit'),
      onAction: handleAction(editActionId),
      onKeyDown: () => { },
    };

    if (isSinglePet) {
      return [editAction];
    }

    return [
      editAction,
      {
        id: removeActionId,
        label: t('petSelector.remove'),
        onAction: handleAction(removeActionId),
        onKeyDown: () => { },
      },
    ];
  }, [
    editActionId,
    handleAction,
    isSinglePet,
    removeActionId,
    t,
  ]);

  const moveBetweenActions = useCallback((factor = 1) => {
    if (selectedActionRef.current) {
      const index = actions
        .findIndex((action) => action.id === selectedActionRef?.current?.id);
      const { length } = listRef.current.childNodes;
      let newIndex = index + factor;
      if (newIndex >= length) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = length - 1;
      }
      const newItem = listRef.current.childNodes[newIndex];
      setActiveDescendant(newItem);
    }
  }, [actions]);

  const handleMenuListKeyDown = useCallback((event) => {
    const { keyCode } = event;
    switch (keyCode) {
      case DOWN_KEY:
      case UP_KEY:
        event.preventDefault();
        moveBetweenActions(keyCode === UP_KEY ? -1 : 1);
        break;

      case ESC_KEY:
      case TAB_KEY:
        event.preventDefault();
        handleClose();
        break;

      case SPACE_KEY:
        event.preventDefault();
        break;
      case ENTER_KEY:
        event.preventDefault();
        handleAction(itemSelectedId)();
        break;
      case HOME_KEY:
        event.preventDefault();
        goFirstItem();
        break;
      case END_KEY:
        event.preventDefault();
        goLastItem();
        break;
      default:
        break;
    }
  }, [
    goFirstItem,
    goLastItem,
    handleAction,
    handleClose,
    itemSelectedId,
    moveBetweenActions,
  ]);

  const hasError = useMemo(() => {
    if (selected) {
      return powerUpErrors.length > 0;
    }
    return petsWithQuoteError.includes(petQuote.petQuoteId);
  }, [petQuote.petQuoteId, petsWithQuoteError, powerUpErrors, selected]);

  return (
    <li ref={itemRef} className="Pet-selector-item" id={itemId}>
      <div
        ref={petTabRef}
        className={'Pet-selector-item-pet'
          + ` ${selected ? ' Pet-selector-item-pet-selected' : ''}`}
        data-error={hasError}
        id={petTabId}
      >
        <button
          aria-disabled={disabled}
          aria-selected={selected}
          className="Pet-selector-item-pet-info"
          id={petInfoId}
          onClick={handleAction(selectActionId)}
          onFocus={handleFocus}
          role="tab"
          type="button"
        >
          <span aria-live="polite" className="Pet-selector-item-pet-info-name">
            {hasError ? (
              <img
                alt=""
                aria-label={t('petSelector.errorPet')}
                src={infoIcon}
              />
            ) : null}

            {petQuote.petName || 'None'}
          </span>

          <span>{petQuoteTotal}</span>
        </button>

        {selected && !hasError ? (
          <button
            ref={menuButtonRef}
            aria-controls={menuListId}
            aria-expanded={isExpanded}
            aria-haspopup
            className="Pet-selector-item-pencil-button"
            id={menuIconId}
            onClick={handleMenuClick}
            onKeyDown={handlePetKeyDown}
            type="button"
          >
            <img
              alt={t('petSelector.menuLabel', { pet: petQuote.petName })}
              src={editIcon}
            />
          </button>
        ) : null}
      </div>

      {isExpanded && (
        <button
          aria-hidden="true"
          aria-label="overlay"
          className="Pet-selector-item-menu-overlay"
          onClick={handleClose}
          tabIndex={-1}
          type="button"
        />
      )}

      <ul
        ref={listRef}
        aria-activedescendant=""
        aria-labelledby={petInfoId}
        className={`Pet-selector-item-menu ${!isExpanded ? 'Gone' : ''}`}
        id={menuListId}
        onKeyDown={handleMenuListKeyDown}
        role="menu"
        style={{
          left: isMobile && !isSinglePet
            ? petTabRef.current?.getBoundingClientRect().x - (petsCount === 2
              ? MARGIN_TO_ALIGN_TWO : MARGIN_TO_ALIGN)
            : 0,
        }}
        tabIndex={-1}
      >
        {actions.map((action) => (
          <ActionItem
            {...action}
            focused={itemSelectedId === action.id}
          />
        ))}
      </ul>
    </li>
  );
};

const PetSelector = ({ hideInteraction = false }) => {
  const store = useSelector(({ quoting }) => quoting);
  const { saveElementById } = useSavedElement();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    data,
    petQuoteSelected,
    petRemovedLoading,
  } = store;

  const petQuoteList = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.ebPetQuoteResponseList || [];
  }, [data]);

  const selectedPetQuote = useMemo(() => petQuoteList
    .find((pet) => pet.petQuoteId === petQuoteSelected), [
    petQuoteList,
    petQuoteSelected,
  ]);

  useCallbackAfterRate(() => {
    saveElementById(`pet-${petQuoteList[petQuoteList.length - 1]?.petQuoteId}`);
  }, [AFTER_RATE_TAG.addPet]);

  const isVisiblePetValidPowerUps = useCallback(() => {
    if (!data) {
      return false;
    }
    const petQuoteRateItem = data.ebPetQuoteResponseList
      .find((pqRateItem) => pqRateItem.petQuoteId === petQuoteSelected);
    const powerUpValidation = validatePowerupsSelected(petQuoteRateItem);
    if (!powerUpValidation.isValid) {
      dispatch(setPowerUpErrors(powerUpValidation.modifierIdNotSelectedList));
    }
    return powerUpValidation.isValid;
  }, [data, dispatch, petQuoteSelected]);

  const handlePetSelection = useCallback((petQuote) => {
    if (!isVisiblePetValidPowerUps()) {
      return;
    }
    if (!petRemovedLoading) {
      dispatch(saveSelectedPetNumber(petQuote.petQuoteId));

      dispatch(restartPowerupErrors());
    }
  }, [dispatch, isVisiblePetValidPowerUps, petRemovedLoading]);

  const arrowIconsData = useMemo(() => {
    const index = petQuoteList
      .findIndex((petQuote) => petQuote.petQuoteId
        === selectedPetQuote?.petQuoteId);

    const arrowData = {
      left: {
        ariaLabel: petQuoteList[index - 1] ? t('petSelector.leftArrow', {
          count: petQuoteList.length,
          newPos: index,
          prevPet: petQuoteList[index - 1]?.petName,
        }) : t('petSelector.leftArrowUnavailable'),
        disabled: false,
        icon: arrowEnabled,
      },
      right: {
        ariaLabel: petQuoteList[index + 1] ? t('petSelector.rightArrow', {
          count: petQuoteList.length,
          newPos: index + 2,
          nextPet: petQuoteList[index + 1]?.petName,
        }) : t('petSelector.rightArrowUnavailable'),
        disabled: true,
        icon: arrowDisabled,
      },
    };
    if (selectedPetQuote?.petQuoteId === petQuoteList[0]?.petQuoteId) {
      arrowData.left.icon = arrowDisabled;
      arrowData.left.disabled = true;
    }
    if (selectedPetQuote?.petQuoteId
      !== petQuoteList[petQuoteList.length - 1]?.petQuoteId) {
      arrowData.right.icon = arrowEnabled;
      arrowData.right.disabled = false;
    }
    return arrowData;
  }, [petQuoteList, selectedPetQuote, t]);

  const handleArrowClick = useCallback((factor = 1) => () => {
    if ((factor > 0 && arrowIconsData.right.disabled)
      || (factor < 0 && arrowIconsData.left.disabled)
    ) {
      return;
    }

    if (isVisiblePetValidPowerUps()) {
      const index = petQuoteList
        .findIndex((petQuote) => petQuote.petQuoteId
          === selectedPetQuote?.petQuoteId);
      if (index >= 0) {
        const { length } = petQuoteList;
        const newIndex = index + factor;
        if (newIndex >= length || newIndex < 0) {
          return;
        }
        const newPetQuote = petQuoteList[newIndex];
        if (newPetQuote) {
          handlePetSelection(newPetQuote);
        }
      }
    }
  }, [
    arrowIconsData,
    handlePetSelection,
    isVisiblePetValidPowerUps,
    petQuoteList,
    selectedPetQuote,
  ]);

  return (
    <div
      className="Pet-selector-wrapper"
      style={petQuoteList.length > 2 ? {
        width: '100%',
      } : null}
    >
      <ul aria-label="Pets" className="Pet-selector-container" role="tablist">
        {petQuoteList.map((petQuote) => (
          <PetSelectorItem
            key={petQuote?.petQuoteId}
            disabled={hideInteraction}
            isSelectedPetValid={isVisiblePetValidPowerUps}
            isSinglePet={petQuoteList.length <= 1}
            onPetSelected={handlePetSelection}
            petQuote={petQuote}
            selected={selectedPetQuote?.petQuoteId === petQuote?.petQuoteId
              && !hideInteraction}
          />
        ))}
      </ul>

      {petQuoteList.length > 2 && !hideInteraction ? (
        <div className="Pet-selector-dots">
          <button
            aria-disabled={arrowIconsData.left.disabled}
            className={'Pet-selector-arrow'
              + `${arrowIconsData.left.disabled
                ? ' Pet-selector-arrow-switched' : ''}`}
            onClick={handleArrowClick(-1)}
            type="button"
          >
            <img
              alt=""
              aria-label={arrowIconsData.left.ariaLabel}
              src={arrowIconsData.left.icon}
            />
          </button>

          <div className="Pet-selector-dots-container">
            {petQuoteList.length > 1 ? petQuoteList.map((petQuote) => (
              <PetDot
                key={petQuote?.petQuoteId}
                petQuote={petQuote}
                petsCount={petQuoteList.length}
                selected={selectedPetQuote?.petQuoteId === petQuote?.petQuoteId}
              />
            )) : null}
          </div>

          <button
            aria-disabled={arrowIconsData.right.disabled}
            className={'Pet-selector-arrow'
              + `${!arrowIconsData.right.disabled
                ? ' Pet-selector-arrow-switched' : ''}`}
            onClick={handleArrowClick(1)}
            type="button"
          >
            <img
              alt=""
              aria-label={arrowIconsData.right.ariaLabel}
              src={arrowIconsData.right.icon}
            />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export { PetSelector };
