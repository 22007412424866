import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CUSTOMER_SERVICE_PHONE_NUMBER } from '../constants';

const useEmployerData = () => {
  const store = useSelector(({ quoting }) => ({
    guidData: quoting.guidData,
    supportPhoneNumber: quoting.supportPhoneNumber,
  }), shallowEqual);

  const supportPhoneNumber = useMemo(() => {
    if (store.supportPhoneNumber) {
      return `${store.supportPhoneNumber}.`;
    }

    if (store.guidData) {
      return `${store.guidData.PhoneNumber}.`;
    }

    return `${CUSTOMER_SERVICE_PHONE_NUMBER}.`;
  }, [store]);

  return {
    data: store?.guidData,
    supportPhoneNumber,
  };
};

export { useEmployerData };
