import { useEffect, useState } from 'react';

const useOnBodyScrollEvent = () => {
  const [verticalScrollValue, setVerticalScrollValue] = useState(0);

  const onScrollBody = () => {
    const newValue = document.body.getBoundingClientRect().top;
    setVerticalScrollValue(newValue);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScrollBody);

    return () => window.removeEventListener('scroll', onScrollBody);
  }, []);

  return {
    isOnTopScreen: verticalScrollValue === 0,
    verticalScrollValue,
  };
};

export { useOnBodyScrollEvent };
