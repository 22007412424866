import React from 'react';
import errorIcon from '../../assets/error.svg';
import infoIcon from '../../assets/info-i.svg';
import './Message.css';

const Message = ({ message = '', type = 'error', show = false }) => (
  <section
    aria-live="polite"
    className={`Message Message-${type}`}
  >
    {show ? (
      <>
        <img alt="" src={type === 'error' ? errorIcon : infoIcon} />

        {message}
      </>
    ) : ''}
  </section>
);

export { Message };
