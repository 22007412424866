/* eslint-disable max-len */
export const ACCOUNT_NUMBER_CHANGED = 'ACCOUNT_NUMBER_CHANGED';
export const ACCOUNT_NUMBER_ERROR_CHANGED = 'ACCOUNT_NUMBER_ERROR_CHANGED';
export const ACCOUNT_TYPE_CHANGED = 'ACCOUNT_TYPE_CHANGED';
export const ADDRESS_BY_ZIP_CODE_LOADED = 'ADDRESS_BY_ZIP_CODE_LOADED';
export const ADDRESS_BY_ZIP_CODE_REQUESTED = 'ADDRESS_BY_ZIP_CODE_REQUESTED';
export const AGE_DATA_ERROR = 'AGE_DATA_ERROR';
export const AGE_DATA_LOADED = 'AGE_DATA_LOADED';
export const AGREE_TERMS_AND_CONDITION_CHANGED = 'AGREE_TERMS_AND_CONDITION_CHANGED';
export const AMERICAN_STATES_LOADED = 'AMERICAN_STATES_LOADED';
export const BREED_DATA_ERROR = 'BREED_DATA_ERROR';
export const BREED_DATA_LOADED = 'BREED_DATA_LOADED';
export const CARD_CVV_CHANGED = 'CARD_CVV_CHANGED';
export const CARD_CVV_ERROR_CHANGED = 'CARD_CVV_ERROR_CHANGED';
export const CARD_NUMBER_CHANGED = 'CARD_NUMBER_CHANGED';
export const CARD_NUMBER_ERROR_CHANGED = 'CARD_NUMBER_ERROR_CHANGED';
export const CONFIRM_ACCOUNT_NUMBER_CHANGED = 'CONFIRM_ACCOUNT_NUMBER_CHANGED';
export const CONFIRM_ACCOUNT_NUMBER_ERROR_CHANGED = 'CONFIRM_ACCOUNT_NUMBER_ERROR_CHANGED';
export const CONSENT_ELECTRONIC_DELIVERY_CHANGED = 'CONSENT_ELECTRONIC_DELIVERY_CHANGED';
export const CONTINUE_WITHOUT_LOGIN = 'CONTINUE_WITHOUT_LOGIN';
export const CREATE_DIAMOND_CLIENT_ATTEMPT = 'CREATE_DIAMOND_CLIENT_ATTEMPT';
export const CREATE_DIAMOND_CLIENT_ERROR = 'CREATE_DIAMOND_CLIENT_ERROR';
export const CREATE_DIAMOND_CLIENT_SUCCESS = 'CREATE_DIAMOND_CLIENT_SUCCESS';
export const CREATE_LIVE_VET_NEW_ACCOUNT_ATTEMPTED = 'CREATE_LIVE_VET_NEW_ACCOUNT_ATTEMPTED';
export const CREDIT_CARD_TYPE_CHANGED = 'CREDIT_CARD_TYPE_CHANGED';
export const CURRENT_QUOTE_STEP_CHANGED = 'CURRENT_QUOTE_STEP_CHANGED';
export const CUSTOM_VET_CITY_CHANGED = 'CUSTOM_VET_CITY_CHANGED';
export const CUSTOM_VET_DATA_CLEARED = 'CUSTOM_VET_DATA_CLEARED';
export const CUSTOM_VET_MODAL_OPENED = 'CUSTOM_VET_MODAL_OPENED';
export const CUSTOM_VET_PRACTICE_NAME_CHANGED = 'CUSTOM_VET_PRACTICE_NAME_CHANGED';
export const CUSTOM_VET_SAVED = 'CUSTOM_VET_SAVED';
export const CUSTOM_VET_STATE_CHANGED = 'CUSTOM_VET_STATE_CHANGED';
export const CUSTOMER_VALIDATED = 'CUSTOMER_VALIDATED';
export const CUSTOMER_VALIDATION_ATTEMPT = 'CUSTOMER_VALIDATION_ATTEMPT';
export const CUSTOMER_VALIDATION_ERROR = 'CUSTOMER_VALIDATION_ERROR';
export const CUSTOMER_VALIDATION_RESTARTED = 'CUSTOMER_VALIDATION_RESTARTED';
export const DEDUCTIBLE_LEGEND_CHANGED = 'DEDUCTIBLE_LEGEND_CHANGED';
export const DIAMOND_CUSTOMER_LOGIN_ATTEMPT = 'DIAMOND_CUSTOMER_LOGIN_ATTEMPT';
export const DIAMOND_CUSTOMER_LOGIN_CLEANED = 'DIAMOND_CUSTOMER_LOGIN_CLEANED';
export const DIAMOND_CUSTOMER_LOGIN_FAILED = 'DIAMOND_CUSTOMER_LOGIN_FAILED';
export const DIAMOND_CUSTOMER_LOGIN_SUCCESS = 'DIAMOND_CUSTOMER_LOGIN_SUCCESS';
export const DIAMOND_CUSTOMER_LOGOUT = 'DIAMOND_CUSTOMER_LOGOUT';
export const DIAMOND_CUSTOMER_RESETED_LOGIN_FAIL = 'DIAMOND_CUSTOMER_RESETED_LOGIN_FAIL';
export const DIAMOND_CUSTOMER_VALIDATION_ATTEMPT = 'DIAMOND_CUSTOMER_VALIDATION_ATTEMPT';
export const DIAMOND_CUSTOMER_VALIDATION_FAILED = 'DIAMOND_CUSTOMER_VALIDATION_FAILED';
export const DIAMOND_CUSTOMER_VALIDATION_LOADED = 'DIAMOND_CUSTOMER_VALIDATION_LOADED';
export const DIAMOND_CUSTOMER_ZIPCODE_CHANGED = 'DIAMOND_CUSTOMER_ZIPCODE_CHANGED';
export const EFFECTIVE_DATE_CUSTOM_CHANGED = 'EFFECTIVE_DATE_CUSTOM_CHANGED';
export const EMPLOYER_ID_VALIDATED = 'EMPLOYER_ID_VALIDATED';
export const EMPLOYER_ID_VALIDATION_ATTEMPT = 'EMPLOYE_ID_VALIDATION_ATTEMPT';
export const ERROR_MODAL_CLOSED = 'ERROR_MODAL_CLOSED';
export const EXPIRATION_DATE_CHANGED = 'EXPIRATION_DATE_CHANGED';
export const EXPIRATION_DATE_ERROR_CHANGED = 'EXPIRATION_DATE_ERROR_CHANGED';
export const FAILED_TO_LOAD_ADDRESS_BY_ZIP_CODE = 'FAILED_TO_LOAD_ADDRESS_BY_ZIP_CODE';
export const FAILED_TO_LOAD_AMERICAN_STATES = 'FAILED_TO_LOAD_AMERICAN_STATES';
export const FAILED_TO_LOAD_HOW_DID_YOU_HEAR_OPTIONS = 'FAILED_TO_LOAD_HOW_DID_YOU_HEAR_OPTIONS';
export const FAILED_TO_LOAD_VETERINARIANS = 'FAILED_TO_LOAD_VETERINARIANS';
export const FETCH_MESSAGE_NO_DATA = 'FETCH_MESSAGE_NO_DATA';
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS';
export const FORGET_PASSWORD_CONFIRM_MODAL_VISIBLE_CHANGED = 'FORGET_PASSWORD_CONFIRM_MODAL_VISIBLE_CHANGED';
export const FORGET_PASSWORD_MODAL_EMAIL_CHANGED = 'FORGET_PASSWORD_MODAL_EMAIL_CHANGED';
export const FORGET_PASSWORD_MODAL_EMAIL_ERROR_CHANGED = 'FORGET_PASSWORD_MODAL_EMAIL_ERROR_CHANGED';
export const FORGET_PASSWORD_MODAL_VISIBLE_CHANGED = 'FORGET_PASSWORD_MODAL_VISIBLE_CHANGED';
export const FORGET_PASSWORD_RESET_PASSWORD_ATTEMTPED = 'FORGET_PASSWORD_RESET_PASSWORD_ATTEMTPED';
export const FORGET_RESET_PASSWORD_FAILED = 'FORGET_RESET_PASSWORD_FAILED';
export const FORGET_RESET_PASSWORD_SUCCEEDED = 'FORGET_RESET_PASSWORD_SUCCEEDED';
export const GET_SUB_ID_SYNONYMS_DATA_FAILED = 'GET_SUB_ID_SYNONYMS_DATA_FAILED';
export const GUID_DATA_LOADED = 'GUID_DATA_LOADED';
export const HOW_DID_YOU_HEAR_OPTIONS_LOADED = 'HOW_DID_YOU_HEAR_OPTIONS_LOADED';
export const LIVE_VET_ACCESS_RESET = 'LIVE_VET_ACCESS_RESET';
export const LIVE_VET_ACCESS_VALIDATED = 'LIVE_VET_ACCESS_VALIDATED';
export const LIVE_VET_ACCESS_VALIDATION_ATTEMPTED = 'LIVE_VET_ACCESS_VALIDATION_ATTEMPTED';
export const LIVE_VET_ACCESS_VALIDATION_FAILED = 'LIVE_VET_ACCESS_VALIDATION_FAILED';
export const LIVE_VET_MODAL_LOGIN_VISIBLE_CHANGED = 'LIVE_VET_MODAL_LOGIN_VISIBLE_CHANGED';
export const LIVE_VET_NEW_CUSTOMER_VALIDATED = 'LIVE_VET_NEW_CUSTOMER_VALIDATED';
export const LIVE_VET_PET_PARENT_VALIDATED = 'LIVE_VET_PET_PARENT_VALIDATED';
export const LIVE_VET_SUBSCRIPTION_ATTEMPT = 'LIVE_VET_SUBSCRIPTION_ATTEMPT';
export const LIVE_VET_SUBSCRIPTION_FAILED = 'LIVE_VET_SUBSCRIPTION_FAILED';
export const LIVE_VET_SUBSCRIPTION_RESET = 'LIVE_VET_SUBSCRIPTION_RESET';
export const LIVE_VET_SUBSCRIPTION_SUCCESS = 'LIVE_VET_SUBSCRIPTION_SUCCESS';
export const LOAD_MULTI_MODAL_DATA_FAILED = 'LOAD_MULTI_MODAL_DATA_FAILED';
export const LOAD_MULTI_MODAL_DATA_SUCCESS = 'LOAD_MULTI_MODAL_DATA_SUCCESS';
export const LOADING_QUOTE_DATA = 'LOADING_QUOTE_DATA';
export const LOADING_QUOTE_DATA_FINISH = 'LOADING_QUOTE_DATA_FINISH';
export const MODAL_CUSTOMER_WELCOME_VISIBLE_CHANGED = 'MODAL_CUSTOMER_WELCOME_VISIBLE_CHANGED';
export const MODAL_PET_CO_PAY_VISIBLE_CHANGED = 'MODAL_PET_CO_PAY_VISIBLE_CHANGED';
export const MULTI_MODAL_CUSTOM_CONTENT_CHANGED = 'MULTI_MODAL_CUSTOM_CONTENT_CHANGED';
export const MULTI_MODAL_CUSTOM_CONTENT_CLEARED = 'MULTI_MODAL_CUSTOM_CONTENT_CLEARED';
export const MULTI_MODAL_NEED_TO_KNOW_VISIBLE_CHANGED = 'MULTI_MODAL_NEED_TO_KNOW_VISIBLE_CHANGED';
export const MULTI_MODAL_PET_CO_PAY_VISIBLE_CHANGED = 'MULTI_MODAL_PET_CO_PAY_VISIBLE_CHANGED';
export const MULTI_MODAL_TERMS_AND_COND_VISIBLE_CHANGED = 'MULTI_MODAL_TERMS_AND_COND_VISIBLE_CHANGED';
export const MULTI_MODAL_WELLNESS_VISIBLE_CHANGED = 'MULTI_MODAL_WELLNESS_VISIBLE_CHANGED';
export const NAME_ON_CARD_CHANGED = 'NAME_ON_CARD_CHANGED';
export const NAME_ON_CARD_ERROR_CHANGED = 'NAME_ON_CARD_ERROR_CHANGED';
export const NEW_CUSTOMER_ADDRESS_LOAD_ATTEMPTED = 'NEW_CUSTOMER_ADDRESS_LOAD_ATTEMPTED';
export const NEW_CUSTOMER_CITY_CHANGED = 'NEW_CUSTOMER_CITY_CHANGED';
export const NEW_CUSTOMER_CONFIRM_PASSWORD_CHANGED = 'NEW_CUSTOMER_CONFIRM_PASSWORD_CHANGED';
export const NEW_CUSTOMER_EMAIL_CHANGED = 'NEW_CUSTOMER_EMAIL_CHANGED';
export const NEW_CUSTOMER_EMAIL_ERROR_CHANGED = 'NEW_CUSTOMER_EMAIL_ERROR_CHANGED';
export const NEW_CUSTOMER_EMPLOYEE_ID_CHANGED = 'NEW_CUSTOMER_EMPLOYEE_ID_CHANGED';
export const NEW_CUSTOMER_LAST_NAME_CHANGED = 'NEW_CUSTOMER_LAST_NAME_CHANGED';
export const NEW_CUSTOMER_NAME_CHANGED = 'NEW_CUSTOMER_NAME_CHANGED';
export const NEW_CUSTOMER_PASSWORD_CHANGED = 'NEW_CUSTOMER_PASSWORD_CHANGED';
export const NEW_CUSTOMER_PASSWORD_CONFIRM_ERROR_CHANGED = 'NEW_CUSTOMER_PASSWORD_CONFIRM_ERROR_CHANGED';
export const NEW_CUSTOMER_PASSWORD_ERROR_CHANGED = 'NEW_CUSTOMER_PASSWORD_ERROR_CHANGED';
export const NEW_CUSTOMER_PHONE_CHANGED = 'NEW_CUSTOMER_PHONE_CHANGED';
export const NEW_CUSTOMER_SECOND_PARENT_EMAIL_CHANGED = 'NEW_CUSTOMER_SECOND_PARENT_EMAIL_CHANGED';
export const NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED = 'NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED';
export const NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED = 'NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED';
export const NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED = 'NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED';
export const NEW_CUSTOMER_STATE_CHANGED = 'NEW_CUSTOMER_STATE_CHANGED';
export const NEW_CUSTOMER_STREET_CHANGED = 'NEW_CUSTOMER_STREET_CHANGED';
export const NEW_CUSTOMER_STREET2_CHANGED = 'NEW_CUSTOMER_STREET2_CHANGED';
export const NEW_CUSTOMER_ZIP_CODE_CHANGED = 'NEW_CUSTOMER_ZIP_CODE_CHANGED';
export const NEW_PET_AGE_SAVED = 'NEW_PET_AGE_SAVED';
export const NEW_PET_BREED_SAVED = 'NEW_PET_BREED_SAVED';
export const NEW_PET_EMAIL_SAVED = 'NEW_PET_EMAIL_SAVED';
export const NEW_PET_NAME_SAVED = 'NEW_PET_NAME_SAVED';
export const NEW_PET_SEX_SAVED = 'NEW_PET_SEX_SAVED';
export const NEW_PET_TYPE_SAVED = 'NEW_PET_TYPE_SAVED';
export const NEW_PET_ZIPCODE_SAVED = 'NEW_PET_ZIPCODE_SAVED';
export const NO_DIAMOND_CLIENT_ID_OR_PC_ERROR = 'NO_DIAMOND_CLIENT_ID_OR_PC_ERROR';
export const NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL = 'NOP_COMMERCE_RECALCULATE_LOCAL_TOTAL';
export const ONE_INC_PAYMENT_BANK_SAVED = 'ONE_INC_PAYMENT_BANK_SAVED ';
export const ONE_INC_PAYMENT_CREDIT_CARD_SAVED = 'ONE_INC_PAYMENT_CREDIT_CARD_SAVED';
export const ONE_INC_PAYMENT_DEFAULTS_SAVED = 'ONE_INC_PAYMENT_DEFAULTS_SAVED';
export const ONE_INC_PAYMENT_SAVED = 'ONE_INC_PAYMENT_SAVED';
export const ONE_INC_PAYMENT_UPDATED = 'ONE_INC_PAYMENT_UPDATED';
export const ONE_INC_PET_PAYMENT_METHOD_SAVED = 'ONE_INC_PET_PAYMENT_METHOD_SAVED';
export const ONE_INC_PET_PAYMENT_UPDATED = 'ONE_INC_PET_PAYMENT_UPDATED';
export const ONE_INC_RESET_ALL = 'ONE_INC_RESET_ALL';
export const ONE_INC_SESSION_CREATED = 'ONE_INC_SESSION_CREATED';
export const ONE_INC_SESSION_REMOVED = 'ONE_INC_SESSION_REMOVED';
export const OPEN_QUOTE_LOADED = 'OPEN_QUOTE_LOADED';
export const OPEN_QUOTE_PENDING_PETS_LOADED = 'OPEN_QUOTE_PENDING_PETS_LOADED';
export const OPEN_SAVED_QUOTE_ATTEMPT = 'OPEN_SAVED_QUOTE_ATTEMPT';
export const OPEN_SAVED_QUOTE_ERROR = 'OPEN_SAVED_QUOTE_ERROR';
export const OPEN_SAVED_QUOTE_EXPIRED = 'OPEN_SAVED_QUOTE_EXPIRED';
export const PAYMENT_METHOD_TYPE_CHANGE = 'PAYMENT_METHOD_TYPE_CHANGE';
export const PAYMENT_PLAN_FREQUENCY_CHANGED = 'PAYMENT_PLAN_FREQUENCY_CHANGED';
export const PET_ADDED = 'PET_ADDED';
export const PET_QUOTE_REMOVAL_ATTEMPT = 'PET_QUOTE_REMOVAL_ATTEMPT';
export const PET_QUOTE_REMOVAL_FAILED = 'PET_QUOTE_REMOVAL_FAILED';
export const PET_QUOTE_REMOVAL_SUCCEED = 'PET_QUOTE_REMOVAL_SUCCEED';
export const PET_REMOVED = 'PET_REMOVED';
export const PET_TAG_COLOR_CHANGED = 'PET_TAG_COLOR_CHANGED';
export const PET_TAG_DATE_ERRORS_CHANGED = 'PET_TAG_DATE_ERRORS_CHANGED';
export const PET_TAG_DATE_OF_BIRTH_CHANGED = 'PET_TAG_DATE_OF_BIRTH_CHANGED';
export const PET_UPDATED = 'PET_UPDATED';
export const POWER_UP_SELECTED = 'POWER_UP_SELECTED';
export const PURCHASE_ATTEMPT = 'PURCHASE_ATTEMPT';
export const PURCHASE_MULTIPLE_ATTEMPT = 'PURCHASE_MULTIPLE_ATTEMPT';
export const PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED = 'PURCHASE_MULTIPLE_QUOTE_STATUS_CHANGED';
export const PURCHASE_COMPLETED = 'PURCHASE_COMPLETED';
export const QUOTE_DATA_LOADED_BY_STORAGE = 'QUOTE_DATA_LOADED_BY_STORAGE';
export const QUOTE_PLAN_SELECTED = 'QUOTE_PLAN_SELECTED';
export const QUOTE_POWER_UP_CHANGED = 'QUOTE_POWER_UP_CHANGED';
export const QUOTE_SELECTED_VALUES_REMOVED = 'QUOTE_SELECTED_VALUES_REMOVED';
export const QUOTE_WELLNESS_SELECTED = 'QUOTE_WELLNESS_SELECTED';
export const QUOTING_PLAN_DATA_LOADED = 'QUOTING_PLAN_DATA_LOADED';
export const RATE_QUOTE_ERROR = 'RATE_QUOTE_ERROR';
export const RATE_QUOTE_UPDATE_ATTEMPT = 'RATE_QUOTE_UPDATE_ATTEMPT';
export const RATE_QUOTE_UPDATE_LOADED = 'RATE_QUOTE_UPDATE_LOADED';
export const RATE_SUMMARY_ATTEMP = 'RATE_SUMMARY_ATTEMP';
export const RATE_SUMMARY_FAILED = 'RATE_SUMMARY_FAILED';
export const RATE_SUMMARY_SUCCESS = 'RATE_SUMMARY_SUCCESS';
export const RECALCULATE_LOCAL_TOTAL = 'RECALCULATE_LOCAL_TOTAL';
export const RECALCULATE_PACKAGED_VALUES_SELECTED = 'RECALCULATE_PACKAGED_VALUES_SELECTED';
export const REMOVE_PET_MODAL_VISIBLE = 'REMOVE_PET_MODAL_VISIBLE';
export const RESET_POWER_UP_ARIA_LABEL = 'RESET_POWER_UP_ARIA_LABEL';
export const RESET_QUOTES_VALUES_SELECTED = 'RESET_QUOTES_VALUES_SELECTED';
export const ROUTING_NUMBER_CHANGED = 'ROUTING_NUMBER_CHANGED';
export const ROUTING_NUMBER_ERROR_CHANGED = 'ROUTING_NUMBER_ERROR_CHANGED';
export const SAME_ADDRESS_FOR_BILLING_CHANGED = 'SAME_ADDRESS_FOR_BILLING_CHANGED';
export const SAVE_DEDUCTIBLE_SELECTED = 'SAVE_DEDUCTIBLE_SELECTED';
export const SAVE_MONTHLY_PLAN_SELECTED = 'SAVE_MONTHLY_PLAN_SELECTED';
export const SAVE_QUOTE_ATTEMPT = 'SAVE_QUOTE_ATTEMPT';
export const SAVE_QUOTE_ERROR = 'SAVE_QUOTE_ERROR';
export const SAVE_QUOTE_MOBILE_VISIBLE_CHANGED = 'SAVE_QUOTE_MOBILE_VISIBLE_CHANGED';
export const SAVE_REIMBURSEMENT_SELECTED = 'SAVE_REIMBURSEMENT_SELECTED';
export const SAVE_SELECTED_ADD_PET_MODAL = 'SAVE_SELECTED_ADD_PET_MODAL';
export const SAVE_SELECTED_EDIT_PET_MODAL = 'SAVE_SELECTED_EDIT_PET_MODAL';
export const SAVE_SELECTED_PET_NUMBER = 'SAVE_SELECTED_PET_NUMBER';
export const SAVE_UPDATED_PET = 'SAVE_UPDATED_PET';
export const SAVE_URL_PARAMETERS = 'SAVE_URL_PARAMETERS';
export const SAVED_QUOTE = 'SAVED_QUOTE';
export const SAVED_QUOTE_MODAL_VISIBLE = 'SAVED_QUOTE_MODAL_VISIBLE';
export const SECOND_ADDRESS_CITY_CHANGED = 'SECOND_ADDRESS_CITY_CHANGED';
export const SECOND_ADDRESS_CITY_ERROR_CHANGED = 'SECOND_ADDRESS_CITY_ERROR_CHANGED';
export const SECOND_ADDRESS_CLEARED = 'SECOND_ADDRESS_CLEARED';
export const SECOND_ADDRESS_STATE_ERROR_CHANGED = 'SECOND_ADDRESS_STATE_ERROR_CHANGED';
export const SECOND_ADDRESS_STREET_CHANGED = 'SECOND_ADDRESS_STREET_CHANGED';
export const SECOND_ADDRESS_STREET_ERROR_CHANGED = 'SECOND_ADDRESS_STREET_ERROR_CHANGED';
export const SECOND_ADDRESS_STREET2_CHANGED = 'SECOND_ADDRESS_STREET2_CHANGED';
export const SECOND_ADDRESS_ZIP_CODE_CHANGED = 'SECOND_ADDRESS_ZIP_CODE_CHANGED';
export const SECOND_ADDRESS_ZIP_ERROR_CHANGED = 'SECOND_ADDRESS_ZIP_ERROR_CHANGED';
export const SECOND_PARENT_FORM_TOGGLED = 'SECOND_PARENT_FORM_TOGGLED';
export const SEND_SMS_APPS_LINKS_ATTEMPT = 'SEND_SMS_APPS_LINKS_ATTEMPT';
export const SEND_SMS_APPS_LINKS_FAILED = 'SEND_SMS_APPS_LINKS_FAILED';
export const SEND_SMS_APPS_LINKS_SUCCESS = 'SEND_SMS_APPS_LINKS_SUCCESS';
export const SIGNED_UP_SMS = 'SIGNED_UP_SMS';
export const SIGNED_UP_SMS_ATTEMPT = 'SIGNED_UP_SMS_ATTEMPT';
export const SUB_ID_SAVED = 'SUB_ID_SAVED';
export const SUB_ID_SYNONYMS_DATA_LOADED = 'SUB_ID_SYNONYMS_DATA_LOADED';
export const TERMS_AND_CONDITION_VALIDATION_CHANGED = 'TERMS_AND_CONDITION_VALIDATION_CHANGED';
export const UNDERSTAND_CONDITIONS_CHANGED = 'UNDERSTAND_CONDITIONS_CHANGED';
export const VETERINARIAN_SET = 'VETERINARIAN_SET';
export const VETERINARIANS_LOAD_ATTEMPT = 'VETERINARIANS_LOAD_ATTEMPT';
export const VETERINARIANS_LOADED = 'VETERINARIANS_LOADED';
export const YOUR_INFO_SCROLL_TO_TOP_TOGGLED = 'YOUR_INFO_SCROLL_TO_TOP_TOGGLED';
export const YOUR_INFO_STEP_CHANGED = 'YOUR_INFO_STEP_CHANGED';
export const RECAPTCHA_VALIDATE_ATTEMPT = 'RECAPTCHA_VALIDATE_ATTEMPT';
export const RECAPTCHA_VALIDATE_LOADED = 'RECAPTCHA_VALIDATE_LOADED';
export const RECAPTCHA_VALIDATE_FAILED = 'RECAPTCHA_VALIDATE_FAILED';
export const RETRY_PURCHASE_ERROR = 'RETRY_PURCHASE_ERROR';
export const FEATURE_FLAGS_LOADED = 'FEATURE_FLAGS_LOADED';
export const FEATURE_FLAGS_FAILED = 'FEATURE_FLAGS_FAILED';
export const FEATURE_FLAGS_REQUESTED = 'FEATURE_FLAGS_REQUESTED';
export const TERMS_AND_POLICY_CHANGED = 'TERMS_AND_POLICY_CHANGED';
export const POWERUP_ID_ERRORS_SET = 'POWERUP_ID_ERRORS_SET';
export const POWERUP_ID_ERRORS_REMOVED = 'POWERUP_ID_ERRORS_REMOVED';
export const POWERUP_ID_ERRORS_RESTARTED = 'POWERUP_ID_ERRORS_RESTARTED';
export const CUSTOMER_PASSWORD_SET = 'CUSTOMER_PASSWORD_SET';
export const LOGIN_B2C_STARTED = 'LOGIN_B2C_STARTED';
export const LOGIN_B2C_FAILED = 'LOGIN_B2C_FAILED';
export const DUPLICATED_VALIDATION_FAILED = 'DUPLICATED_VALIDATION_FAILED';
export const DUPLICATED_VALIDATION_MODAL_CLOSED = 'DUPLICATED_VALIDATION_MODAL_CLOSED';
export const ALL_PETS_POWERUPS_VALIDATED = 'ALL_PETS_POWERUPS_VALIDATED';
