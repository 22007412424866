import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Message } from '../common/Message';

const IncompleteSelectionError = () => {
  const { t } = useTranslation('common');
  const store = useSelector(({ quoting }) => quoting);
  const {
    powerUpErrors,
    petsWithQuoteError,
    petQuoteSelected,
  } = store;

  const show = useMemo(() => {
    if (petsWithQuoteError.length === 1
      && petsWithQuoteError.includes(petQuoteSelected)) {
      return powerUpErrors.length > 0;
    }
    return !!petsWithQuoteError.length;
  }, [petQuoteSelected, petsWithQuoteError, powerUpErrors.length]);

  return (
    <Message
      message={t('incompletePowerups')}
      show={show}
      type="error"
    />
  );
};

export { IncompleteSelectionError };
